import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./addPageContent/Sidebar";
import CustomJson from "./addPageContent/CustomJson";
import CustomHtml from "./addPageContent/CustomHtml";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Error, Success, handleCancel } from "../../../reusableComponents/Alerts";
import Builder from "./addPageContent/Builder";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import {
  useAddLandingPageMutation,
  useGetLandingPageDataQuery,
  useGetPageQuery,
  useGetPagesEditorOptionQuery,
  useUpdatePageMutation,
} from "../../../../redux/api/pagesApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { useTranslation } from "react-i18next";
import he from "he";
import Loading from "../../../reusableComponents/loading";
import ThemeModal from "../../channels/emails/Modal/ThemeModal";
import Button from "../../../reusableComponents/Buttons/Button";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { useSelector } from "react-redux";

function NewLandingPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const isEdit = location.pathname.includes("edit");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [primarycolorTab, setPrimaryColorTab] = useState(4);
  const [isOpen, setIsOpen] = useState(false);
  const [themes, setThemes] = useState([]);
  const [preferenceCenterPages, setPreferenceCenterPages] = useState([]);
  const [errors, setErrors] = useState({});
  const [pages, setPages] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState();

  useEffect(() => {
    setIsOpenModal(isEdit ? false : Object.keys(themes).length > 0 ? true : false);
  }, [themes]);

  const [addLandingPageItem, { isLoading: isAddLoading }] = useAddLandingPageMutation();
  const [updatePages, { isLoading: isEditLoading }] = useUpdatePageMutation(id);
  const isSaving = isEditLoading || isAddLoading;

  const { data, isFetching, isLoading } = useGetLandingPageDataQuery(id);

  const { data: editorData } = useGetPagesEditorOptionQuery();
  const { data: getPage } = useGetPageQuery(id, { skip: !id });
  const [applyAndCloseTemplate, setApplyAndCloseTemplate] = useState(null);
  const [modifiedData, setModifiedData] = useState({
    title: "",
    alias: "",
    category: null,
    isPublished: true,
    language: null,
    metaDescription: "",
    redirectType: null,
    redirectUrl: "",
    customJson: null,
    customHtml: "",
    translationParent: [],
    publishUp: null,
    publishDown: null,
    isPreferenceCenter: false,
    noIndex: null,
    template: "blank",
  });
  console.log(modifiedData);

  useEffect(() => {
    if (id && getPage) {
      let pageData = getPage?.page;
      if (isClone) {
        pageData = { ...pageData, isPublished: false };
      }

      setModifiedData({
        ...pageData,
        category: pageData?.category
          ? {
              label: pageData?.category?.title,
              value: pageData?.category?.id,
            }
          : null,
      });
    }
  }, [getPage]);

  useEffect(() => {
    if (data?.featureThemesData) {
      setThemes(data?.featureThemesData);
    }

    if (data?.pagesListData?.pages) {
      setPreferenceCenterPages(data.pagesListData.pages);
    }

    if (data?.groupListData) {
      setPages(data?.groupListData);
    }
  }, [id, data]);

  const handleAddPage = async (params) => {
    console.log("🚀 ~ handleAddPage ~ params:", params);
    if (modifiedData?.title.length < 1) {
      errors.title = true;
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      setErrors({ ...errors });
    } else {
      delete errors.title;
      setErrors({ ...errors });
    }

    if (Object.keys(errors).length === 0) {
      try {
        let response;
        const payload = {
          ...modifiedData,
          category:
            modifiedData.category && typeof modifiedData.category === "object"
              ? modifiedData.category.value
              : modifiedData.category,
          translationParent:
            modifiedData.translationParent && typeof modifiedData.translationParent === "object"
              ? modifiedData.translationParent.id
              : modifiedData.translationParent,
        };

        if (id && !isClone) {
          response = await updatePages({ ...payload, id });
        } else {
          response = await addLandingPageItem(payload);
        }

        if (!response?.error) {
          Success(
            `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
              type: id ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
            })}`
          ).then((result) => {
            if (result.isConfirmed && params === "savebuilder") {
              navigate(`/pages/view/${response?.data?.page?.id}`);
            } else {
              navigate(`/pages/edit/${response?.data?.page?.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };
  const handleSubmit = async (params) => {
    await applyAndCloseTemplate(params);
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/pages", navigate),
      show: false,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleSubmit("savebuilder"), // Pass the parameter "savebuilder"
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleSubmit("applyBuilder"), // Pass the parameter "applyBuilder"
      show: false,
    },
  ];
  const themesButton = [
    {
      name: "themes",
      title: t("caymland.core.filter.themes"),
      icon: <StyleOutlinedIcon size={15} />,
      onClick: () => setIsOpenModal(!isOpenModal),
    },
  ];

  const { isAdmin } = useSelector((state) => state?.auth?.user?.role || {});

  return (
    <Fragment>
      <ThemeModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        modifiedData={modifiedData}
        setModifiedData={setModifiedData}
        themes={themes}
      />
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          alertMessage={alertMessage}
          showAlert={showAlert}
          buttons={buttons}
          loading={isSaving}
          title={
            !isClone && id
              ? t("caymland.page.header.edit", { name: he.decode(modifiedData.title) })
              : t("caymland.page.header.new")
          }
        />
      )}
      <Wrapper
        childrenClassOne={"w-75 json-html-style"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton applyPadding={true} />
          ) : (
            <>
              <Nav className="nav-primary w-full flex justify-between" tabs>
                <div className="flex">
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 4 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(4)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {"Builder"}
                    </NavLink>
                  </NavItem>
                  {isAdmin && (
                    <>
                      {" "}
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 2 ? "active" : ""}
                          onClick={() => setPrimaryColorTab(2)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.email.custom.json")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 3 ? "active" : ""}
                          onClick={() => setPrimaryColorTab(3)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.email.custom.html")}
                        </NavLink>
                      </NavItem>{" "}
                    </>
                  )}
                </div>
                <div style={{ padding: "5px" }}>
                  {Object.keys(themes).length > 0 && <Button height={30} buttons={themesButton} />}
                </div>
              </Nav>
              <TabContent activeTab={primarycolorTab} style={{ display: "flex" }}>
                <TabPane tabId={4} style={{ flex: "1" }}>
               
                    <Builder
                      setModifiedData={setModifiedData}
                      modifiedData={modifiedData}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      toggle={() => setIsOpen(!isOpen)}
                      loading={isFetching}
                      currentId={id}
                      apply={handleAddPage}
                      setApplyAndCloseTemplateRef={setApplyAndCloseTemplate} // Pass the setter as prop
                    />
               
                </TabPane>
                <TabPane tabId={2} style={{ flex: "1" }}>
                  <CustomJson modifiedData={modifiedData} setModifiedData={setModifiedData} />
                </TabPane>
                <TabPane tabId={3} style={{ flex: "1" }}>
                  <CustomHtml modifiedData={modifiedData} setModifiedData={setModifiedData} />
                </TabPane>
              </TabContent>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              pages={pages}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
      />
    </Fragment>
  );
}

export default NewLandingPage;
