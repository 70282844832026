import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { formatQuery } from "react-querybuilder";
import { Error, Success, SlideAlert, handleCancel } from "../../reusableComponents/Alerts";
import Swal from "sweetalert2";
import { TbRefresh } from "react-icons/tb";
import QueryFiltersReusible from "../../reusableComponents/ReusableQueryFilters/QueryFiltersReusible";
import Button from "../../reusableComponents/Buttons/Button";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Checkbox, Text, TextArea } from "../../reusableComponents/Inputs";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import { hasOperatorWithTilde } from "./addNew";
import {
  useAddSegmentsCountMutation,
  useAddSegmentsMutation,
  useGetAddEditSegmentsQuery,
  useGetDevicesQuery,
  useGetSegmentsQuery,
  useUpdateSegmentsMutation,
} from "../../../redux/api/segmentsApi";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import TitleHeader from "../../reusableComponents/TitleHeader";
import { useGetCampaignsQuery } from "../../../redux/api/campaignsApi";
import { useGetEmailsQuery } from "../../../redux/api/emailsApi";
import { useGetTagsQuery } from "../../../redux/api/tagsApi";
import { useGetStagesQuery } from "../../../redux/api/stagesApi";
import { useTranslation } from "react-i18next";
import { useGetGlobalCategoriesQuery } from "../../../redux/api/categoriesApi";

const filterQuery = (q, newObject) => {
  newObject.condition = q.combinator.toUpperCase();
  newObject.rules = q?.rules?.map((rule, index) => {
    if (rule?.hasOwnProperty("rules")) {
      return filterQuery(q?.rules[index], new Object());
    } else {
      return {
        id: rule.field.field,
        field: rule.field.field,
        type: rule.field.type,
        input: rule.field.input,
        object: rule.field.object || "lead",
        operator: rule.operator,
        value: rule.value,
      };
    }
  });
  return newObject;
};

const transformDeviceData = (data) => {
  return Object.entries(data).map(([key, label]) => ({
    value: key,
    label: label,
  }));
};

const generateOptions = (dataToFilter) => {
  if (dataToFilter && Array.isArray(dataToFilter.data)) {
    const publishedData = dataToFilter.data.filter((x) => x.isPublished === true);

    return publishedData.map((x) => ({
      value: x.id.toString(),
      label: x.name,
    }));
  }
  return [];
};

const generateTags = (dataToFilter) => {
  if (dataToFilter && Array.isArray(dataToFilter.data)) {
    return dataToFilter?.data.map((x) => ({
      value: x.id.toString(),
      label: x.tag,
    }));
  }
  return [];
};

function EditCloneSegment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const id = location?.state?.id || location?.state || useParams().id;

  const [segment, setSegment] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [query, setQuery] = useState({
    combinator: "and",
    rules: [],
  });
  const [fields, setFields] = useState([]);
  const [treffer, setTreffer] = useState();
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const ClonePath = location.pathname.includes("/clone");
  const EditPath = location.pathname.includes("/edit");

  const newSegment = useRef({
    isGlobal: false,
    isPublished: false,
    isPreferenceCenter: false,
    name: "",
  });

  const { data: campaignsData } = useGetCampaignsQuery({});
  const { data: segmentsData } = useGetSegmentsQuery({});
  const { data: emailsData } = useGetEmailsQuery({});
  const { data: tagsData } = useGetTagsQuery({});
  const { data: stagesData } = useGetStagesQuery({});
  const { data: devicesData } = useGetDevicesQuery();
  const { data: globalCategory } = useGetGlobalCategoriesQuery();
  const { data, isFetching, isLoading, refetch } = useGetAddEditSegmentsQuery(id);
  const [updateSegment, { isLoading: isEditLoading }] = useUpdateSegmentsMutation();
  const [addSegment] = useAddSegmentsMutation();
  const [addSegmentCount] = useAddSegmentsCountMutation();

  const campaignOptions = generateOptions(campaignsData);
  const segmentOptions = generateOptions(segmentsData);
  const emailOptions = generateOptions(emailsData);
  const tagOptions = generateTags(tagsData);
  const stageOptions = generateOptions(stagesData);
  const deviceBrands = devicesData && devicesData.device_brand ? transformDeviceData(devicesData.device_brand) : [];

  const deviceOSes = devicesData && devicesData.device_os ? transformDeviceData(devicesData.device_os) : [];
  const deviceTypes = devicesData && devicesData.device_type ? transformDeviceData(devicesData.device_type) : [];

  const formatInitialQuery = (q, newObject) => {
    newObject.combinator = q?.condition?.toLowerCase() || "and";
    newObject.rules = q?.rules.map((rule, index) => {
      if (rule.hasOwnProperty("rules")) {
        return formatInitialQuery(q.rules[index], new Object());
      } else {
        if (!rule) return;
        const currentField = fields
          .map((f) => {
            return f?.options
              .map((o) => {
                if (o.hasOwnProperty("list"))
                  return {
                    name: o.name,
                    label: o.label,
                    operators: o.operators,
                    list: o.list,
                  };
                return { name: o.name, label: o.label, operators: o.operators };
              })
              .flat(1);
          })
          .flat(1)
          .filter((o) => o.name === rule.field)[0];
        return {
          field: currentField?.hasOwnProperty("list")
            ? {
                field: rule.field,
                type: rule.type,
                id: rule.id,
                input: rule.input,
                object: rule.object,
                operators: currentField?.operators,
                label: currentField?.label,
                operator: rule.operator,
                list: currentField?.list,
              }
            : {
                field: rule.field,
                type: rule.type,
                id: rule.id,
                input: rule.input,
                object: rule.object,
                operators: currentField?.operators,
                label: currentField?.label,
                operator: rule.operator,
              },
          operator: rule.operator,
          value: rule.value,
        };
      }
    });
    return newObject;
  };

  const fetchFilterOptions = async () => {
    const filters = filter;

    const generateFilterObject = (filterName, filterData) => {
      return {
        label: filterName,
        options: Object.entries(filterData).map(([key, value]) => ({
          name: key,
          label: value.label,
          object: value.object,
          operators: Object.entries(value.operators).map(([key2, value2]) => ({
            name: key2,
            label: value2,
          })),
          ...value.properties,
          group: filterName,
        })),
      };
    };

    const emptyFilter = {
      label: t("caymland.segment.empty.filter"),
      options: [
        {
          group: "Leerer_Filter",
          label: t("caymland.segment.empty.filter"),
          name: "Leerer Filter",
          operators: [
            { name: "not_empty", label: t("caymland.core.isempty") },
            { name: "equals", label: t("caymland.lead.list.form.operator.equals") },
          ],
          type: "text",
        },
      ],
    };
    if (!filters) {
      return;
    } else {
      const tempFields = Object.entries(filters).map(([key, value]) => generateFilterObject(key, value));

      setFields([emptyFilter, ...tempFields]);
    }
  };

  const saveAndClose = async (params) => {
    let filters = {};
    // segment.filters = query;
    filters.valid = true;
    filterQuery(query, filters);

    const updatedSegment = { ...segment, filters };

    if (filters.rules.length === 0 || hasOperatorWithTilde(filters)) {
      if (updatedSegment.name === "") {
        setError(true);
      }
      setShowAlert(true);
      setAlertMessage(t("caymland.core.alert.message.filter"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
      return;
    }

    if (updatedSegment.name === "") {
      setError(true);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);

      return;
    }

    let response;

    if (ClonePath) {
      response = await addSegment({
        name: updatedSegment.name,
        filters: updatedSegment.filters,
        description: updatedSegment.description || "",
        isPublished: updatedSegment.isPublished,
        isGlobal: updatedSegment.isGlobal,
        isPreferenceCenter: updatedSegment.isPreferenceCenter,
      }).unwrap();
    }
    if (EditPath) {
      response = await updateSegment({
        id: updatedSegment.id,
        ...updatedSegment,
      }).unwrap();
    }

    try {
      if (!response?.error) {
        refetch();
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
          })}`
        ).then((result) => {
          if (result.isConfirmed && params === "save") {
            navigate(`/segments/view/${response?.list.id}`);
          } else {
            navigate(`/segments/edit/${response?.list?.id}`);
          }
        });
      } else {
        Error(t("caymland.message.error"));
      }
    } catch {}
  };

  const handleTreffer = async () => {
    try {
      let filters = {};
      filters.valid = newSegment.current.isPublished;
      // filters.valid = true;
      filterQuery(query, filters);
      newSegment.current["filters"] = filters;

      const requestBody = {
        result: {
          ...filters,
        },
      };
      const response = await addSegmentCount(requestBody).unwrap();
      setTreffer(response.segmentCount.count);
    } catch (error) {}
  };

  useEffect(() => {
    if (id && data) {
      let segmentData = data?.segment?.list;
      if (ClonePath) {
        segmentData = { ...segmentData, isPublished: false };
      }

      setSegment(segmentData);
      setQuery({
        ...formatInitialQuery(segmentData?.filters, new Object()),
        valid: segmentData?.filters?.valid,
      });
      setFilter(data?.segmentFilter?.filters);
    }
  }, [id, data, ClonePath]);
  useEffect(() => {
    if (filter) {
      fetchFilterOptions();
    }
  }, [filter]);

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/segments", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => saveAndClose("save"),
    },
    {
      name: "apply",
      title: "Apply",
      icon: <FaCheckDouble size={15} />,
      onClick: () => saveAndClose(""),
    },
  ];

  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isEditLoading}
          title={
            ClonePath ? t("caymland.lead.list.header.new") : t("caymland.lead.list.header.edit", { name: segment.name })
          }
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton />
          ) : (
            <>
              <QueryFiltersReusible
                query={query}
                setQuery={setQuery}
                loading={isFetching}
                fields={fields || []}
                campaignData={campaignOptions}
                segmentData={segmentOptions}
                emailData={emailOptions}
                tagOptions={tagOptions}
                stageOptions={stageOptions}
                deviceBrands={deviceBrands}
                deviceOSes={deviceOSes}
                deviceTypes={deviceTypes}
                globalCategory={globalCategory}
              />
              <div className="d-flex w-100" style={{ justifyContent: "end", marginTop: "20px" }}>
                <Button
                  loading={isLoading}
                  buttons={[
                    {
                      name: "treffer",
                      title: `${treffer || 0} ${t("caymland.page.thead.hits")}`,
                      icon: <TbRefresh size={15} />,
                      onClick: () => handleTreffer(),
                    },
                  ]}
                />
              </div>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <>
              <div className="flex items-center gap-10">
                <div className="w-100">
                  <Text
                    name="name"
                    value={segment?.name && segment?.name}
                    errors={error}
                    onChange={(e) => {
                      const newName = e.target.value;
                      const updatedSegment = { ...segment, name: newName };
                      setSegment(updatedSegment);
                      setRerender(!rerender);
                      setError(false);
                    }}
                    label={t("caymland.core.name")}
                    required={true}
                  />
                </div>
                <div className="w-100">
                  <Text
                    name="Alias"
                    value={segment?.alias && segment?.alias}
                    onChange={(e) => {
                      const newName = e.target.value;
                      const updatedSegment = { ...segment, alias: newName };
                      setSegment(updatedSegment);
                      setRerender(!rerender);
                    }}
                    label="Alias"
                    tooltip={t("caymland.lead.list.help.alias")}
                  />
                </div>
              </div>
              <div className="w-full small-editor">
                <TextArea
                  label={t("caymland.lottery.table.row.description")}
                  value={segment?.description && segment?.description}
                  onChange={(data) => {
                    const newName = data;
                    const updatedSegment = {
                      ...segment,
                      description: newName,
                    };
                    setSegment(updatedSegment);
                    setRerender(!rerender);
                  }}
                />
              </div>
              <div className="switch-holder">
                <Checkbox
                  name="visible-on-forms"
                  value={segment?.isGlobal ? true : false}
                  onChange={(e) =>
                    setSegment(() => {
                      return { ...segment, isGlobal: e.target.checked };
                    })
                  }
                  label={t("caymland.lead.list.form.isglobal")}
                  tooltip={t("caymland.lead.list.form.isglobal.tooltip")}
                />
              </div>

              <div className="switch-holder">
                <Checkbox
                  name="visible-on-forms1"
                  value={segment?.isPreferenceCenter ? true : false}
                  onChange={(e) =>
                    setSegment(() => {
                      return {
                        ...segment,
                        isPreferenceCenter: e.target.checked,
                      };
                    })
                  }
                  label={t("caymland.lead.list.form.isPreferenceCenter")}
                  tooltip={t("caymland.lead.list.form.isPreferenceCenter.tooltip")}
                />
              </div>

              <div class="switch-holder">
                <Checkbox
                  name="visible-on-forms2"
                  value={segment?.isPublished}
                  onChange={(e) =>
                    setSegment(() => {
                      return { ...segment, isPublished: e.target.checked };
                    })
                  }
                  label={t("caymland.core.form.published")}
                />
              </div>
            </>
          )
        }
      />
    </>
  );
}

export default EditCloneSegment;
