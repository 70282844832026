import React, { Fragment, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";
import {
  useDeleteClassificationMutation,
  useGetClassificationsQuery,
  useUpdateClassificationMutation,
} from "../../../../redux/api/classificationApi";

function Classification(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const name = "Classifications";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem("orderByClassifications") ? sessionStorage.getItem("orderByClassifications") : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  const { data, isFetching, refetch } = useGetClassificationsQuery(query);
  const [updateClassification] = useUpdateClassificationMutation(id);

  const [deleteClassification] = useDeleteClassificationMutation();

  const handleTogglePublished = async (data) => {
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
    };
    await updateClassification(updatedData);
    await refetch();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.core.name"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          handleTogglePublished={handleTogglePublished}
          link="mailbox-admin/classifications"
          permissionKey="mailbox:classification"
          view={false}
        />
      ),
    },
    {
      accessorKey: "alias",
      accessorFn: (row) => row.alias,
      header: t("caymland.mailbox.contact.field.alias"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.alias,
    },
    {
      accessorKey: "type",
      accessorFn: (row) => row.type,
      header: t("caymlandb2b.opportunity.value_type"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.type,
    },
  ]);

  const actionButtons = ActionButtons({
    link: "mailbox-admin/classification",
    deleteItem: deleteClassification,
    refetch: refetch,
    permissionKey: "mailbox:classification",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "mailbox/classifications",
    refetch: refetch,
    permissionKey: "mailbox:classification",
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => navigate("new"),
          },
        ]}
        title={t("caymland.mailbox.classification.header")}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        isFetching={isFetching}
        linkNavigate="edit"
      />
    </Fragment>
  );
}

export default Classification;
