import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const fieldsApi = createApi({
  reducerPath: "fieldsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Fields"],
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getContactFields: build.query({
      query: (queryArgs = {}) => {
        const queryString = qs.stringify(queryArgs);
        return `/api/fields/contact?limit=400${queryString ? `&${queryString}` : ""}`;
      },
      transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
      // providesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    }),
    getCompanyFields: build.query({
      query: () => "/api/fields/company?limit=400",
      transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
    }),
    getFieldsList: build.query({
      query: ({ query, fieldsType }) =>
        `/api/fields/${fieldsType}?${qs.stringify({
          ...{ start: query.pageIndex * query.pageSize, limit: query.pageSize },
          ...query,
        })}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    }),
    updateFields: build.mutation({
      query: ({ object = "contact", objectId, field }) => ({
        url: `/api/fields/${object}/${objectId}/edit`,
        method: "PUT",
        body: field,
      }),
      invalidatesTags: ["Fields", "Batch"],
    }),
    updatePatchFields: build.mutation({
      query: ({ object = "contact", objectId, field }) => ({
        url: `/api/fields/${object}/${objectId}/edit`,
        method: "PATCH",
        body: field,
      }),
      invalidatesTags: ["Fields", "Batch"],
    }),
    deleteFields: build.mutation({
      query: ({ object = "lead", objectId }) => ({
        url: `/api/fields/${object}/${objectId}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: ({ objectId }, { dispatch }) => {
        dispatch(
          fieldsApi.util.updateQueryData("getContactFields", "getCompanyFields", undefined, (draft) => {
            draft.data = draft.data.filter((field) => field.id !== objectId);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    }),
    createField: build.mutation({
      query: ({ objectType, field }) => {
        delete field.id;
        return {
          url: `/api/fields/${objectType}/new`,
          method: "POST",
          body: field,
        };
      },
      invalidatesTags: [{ type: "Fields" }],
    }),
    getFieldById: build.query({
      query: ({ object = "lead", objectId }) => `/api/fields/${object}/${objectId}`,
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    }),
    reorderFields: build.mutation({
      query: ({ type, fieldOrder }) => ({
        url: `/api/fields/${type}/reorder`,
        method: "POST",
        body: fieldOrder,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: fieldOrder.field }],
    }),
  }),
});

export const {
  useGetContactFieldsQuery,
  useGetCompanyFieldsQuery,
  useGetFieldsListQuery,
  useUpdateFieldsMutation,
  useDeleteFieldsMutation,
  useCreateFieldMutation,
  useGetFieldByIdQuery,
  useReorderFieldsMutation,
  useUpdatePatchFieldsMutation,
} = fieldsApi;
