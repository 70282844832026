import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { Label } from "../Inputs";
import { t } from "i18next";

const TimezoneSelect = ({
  onChange,
  value,
  label,
  name,
  errors = false,
  required,
  viewEdit = false,
  placeholder,
  defaultTimeZone = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [displayedPlaceholder, setDisplayedPlaceholder] = useState(placeholder);

  let timezoneOptions = useSelector((state) => state?.settings?.data?.timezone);

  if (!Array.isArray(timezoneOptions)) {
    timezoneOptions = [];
  }

  const defaultTimezoneOption = {
    label: "Default",
    options: [{ label: t("caymland.user.user.form.defaulttimezone"), value: "" }],
  };

  const options = defaultTimeZone
    ? [defaultTimezoneOption, ...timezoneOptions]
    : [defaultTimezoneOption, ...timezoneOptions];

  useEffect(() => {
    if (value) {
      const foundOption = findSelectedOption(value);
      if (foundOption) {
        setSelectedOption(foundOption);
        setDisplayedPlaceholder(foundOption.label);
      }
    } else {
      setSelectedOption(defaultTimezoneOption.options[0]);
      setDisplayedPlaceholder(defaultTimezoneOption.options[0].label);
    }
  }, [value, defaultTimeZone]);

  const findSelectedOption = (value) => {
    if (!options) return null;

    if (value === "" && defaultTimeZone) {
      return defaultTimezoneOption.options[0];
    }

    for (let group of options) {
      const foundOption = group.options.find((option) => option.value === value);
      if (foundOption) return foundOption;
    }
    return null;
  };

  const loadOptions = (inputValue, callback) => {
    const groupedOptions = options?.map((group) => {
      const filteredOptions = group.options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      return {
        label: group.label,
        options: filteredOptions,
      };
    });
    setTimeout(() => {
      callback(groupedOptions);
    }, 1000);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    setDisplayedPlaceholder(option.label);
    onChange(option);
  };
  return (
    <>
      {label && label?.length && <Label name={name} label={label} required={required} />}
      <AsyncSelect
        key={options}
        loadOptions={loadOptions}
        defaultOptions
        value={selectedOption}
        onChange={handleChange}
        placeholder={displayedPlaceholder}
        isClearable
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "#135f95",
            primary: "#135f95",
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            outline: "none !important",
            height: viewEdit ? "34px" : "40px",
            minHeight: viewEdit ? "34px" : "40px",
            fontSize: "15px",
            backgroundColor: viewEdit ? "inherit" : "white",
            border: viewEdit ? "0" : "",
            marginBottom: viewEdit ? "3px" : "0",
            boxShadow: viewEdit ? "none" : "",
            borderColor: errors ? "#d32f2f" : base.borderColor,
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: viewEdit ? "none" : "scale(1.003)",
              boxShadow: viewEdit ? "none" : "0 6px 20px rgba(0, 0, 0, 0.1)",
              borderColor: errors ? "#d32f2f" : base["&:hover"].borderColor,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#347AA6" : "white",
            color: state.isSelected ? "white" : "#333",
            padding: "10px 2px",
            fontSize: "15px",
            transition: "background-color 0.2s ease",
            paddingLeft: "20px",
            "&:active": {
              color: "white",
              backgroundColor: "#135f95",
            },
            "&:hover": {
              color: "white",
              backgroundColor: "#135f95",
            },
            "&:focus": {
              color: "white",
            },
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: "#606060",
            ":hover": {
              backgroundColor: "#135f95",
              color: "white",
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            fontSize: "15px",
            backgroundColor: " #e7e7e7",
            borderRadius: "5px",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            fontSize: "15px",
            color: "#606060",
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "#6C757D",
            fontSize: viewEdit ? "13px" : "15px",
          }),
          group: (base, state) => ({
            ...base,
            // backgroundColor: "#f6f6f6",
            padding: "10px 2px",
          }),

          groupHeading: (base, state) => ({
            ...base,
            fontWeight: "bold",
            paddingLeft: "5px",
            color: "#333",
          }),
          menu: (provided) => ({ ...provided, zIndex: 99999999 }),
        }}
      />
    </>
  );
};

export default TimezoneSelect;
