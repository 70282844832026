import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaAsterisk, FaGlobeEurope, FaEyeSlash } from "react-icons/fa";
import { GrKey } from "react-icons/gr";
import { FiFilter } from "react-icons/fi";
import { HiOutlineLockClosed } from "react-icons/hi";
import { BiAddToQueue } from "react-icons/bi";
import {
  useDeleteFieldsMutation,
  useGetFieldsListQuery,
  useReorderFieldsMutation,
  useUpdateFieldsMutation,
} from "../../../redux/api/fieldsApi";
import Header from "../../reusableComponents/Header/Header";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { MRT_CustomHeaderRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomHeaderRowsAction";
import { MRT_CustomRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomRowsAction";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { t } from "i18next";
import { useGetRulesQuery, useReorderProductFieldsMutation, useUpdateRuleMutation } from "../../../redux/api/ruleApi";

function CustomFields(props) {
  const navigate = useNavigate();
  const [order, setOrder] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  const [fieldsType, setFieldsType] = useState("contact");

  const name = "Rules";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "ASC",
    orderBy: sessionStorage.getItem("orderByRules") ? sessionStorage.getItem("orderByRules") : "order",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });
  const { data, isFetching, refetch } = useGetRulesQuery(query);
  const [updateField] = useUpdateRuleMutation();
  const [deleteField] = useDeleteFieldsMutation();
  const [reorderFields] = useReorderProductFieldsMutation();

  useEffect(() => {
    if (data) {
      const newOrder = Array.isArray(data.data) ? data.data.map((item) => item.id) : [];
      setDataTable(data);
      setOrder(newOrder);
    }
  }, [data]);

  const handleTogglePublished = async (data) => {
    const id = data?.id;
    const updatedData = { ...data, isPublished: !data.isPublished };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateField({ id: id, isPublished: !data.isPublished });

    await refetch();
  };

  const handleReorder = async (newOrder) => {
    console.log(newOrder);

    // Map newOrder to the desired payload structure
    const payload = {
      order: newOrder.map((id, index) => ({
        id: id,
        order: index + 1, // Assign the new order based on index
      })),
    };

    console.log(payload); // Debug to verify the payload structure

    // Update the local state for dataTable fields
    const newFields = newOrder.map((id) => dataTable.data.find((item) => item.id === id));

    setDataTable({
      ...dataTable,
      fields: newFields,
    });

    // Send the payload to the API
    try {
      await reorderFields({ fieldOrder: payload }).unwrap();
    } catch (error) {
      console.error("Failed to reorder fields:", error);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "status",
      accessorFn: (row) => row.status,
      header: t("caymland.brochures.orders.status"),
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent data={cell.row.original} link="mailbox/rules" handleTogglePublished={handleTogglePublished} />
      ),
    },
    {
      accessorKey: "from",
      accessorFn: (row) => row.from,
      header: t("caymland.mailbox.message.from"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.from,
    },
    {
      accessorKey: "recipient",
      accessorFn: (row) => row.recipient,
      header: t("caymland.mailbox.rules.recipient"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.recipient,
    },
    {
      accessorKey: "language",
      accessorFn: (row) => row.language,
      header: t("caymland.core.language"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.language,
    },
    {
      accessorKey: "classification",
      accessorFn: (row) => row.classification,
      header: t("caymland.mailbox.classification.index"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.classification,
    },
    {
      accessorKey: "targetFolder",
      accessorFn: (row) => row.targetFolder,
      header: t("caymland.mailbox.rules.folder"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.targetFolder,
    },
    {
      accessorKey: "autoReply",
      accessorFn: (row) => row.autoReply,
      header: t("caymland.mailbox.rules.auto_reply"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.autoReply,
    },
    {
      accessorKey: "forward",
      accessorFn: (row) => row.forward,
      header: t("caymland.mailbox.rules.forward"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.forward,
    },
    {
      accessorKey: "saveIdentity",
      accessorFn: (row) => row.saveIdentity,
      header: t("caymland.mailbox.rules.save_identity"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.saveIdentity,
    },
    {
      accessorKey: "campaign",
      accessorFn: (row) => row.campaign,
      header: t("caymland.mailbox.rules.add_to_campaign"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.campaign,
    },
    {
      accessorKey: "order",
      accessorFn: (row) => row.order,
      header: t("caymland.mailbox.rules.order"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.order,
    },
  ]);

  const buttons = [
    {
      name: "addNew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={15} />,
      onClick: () => navigate("/mailbox-admin/rules/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "mailbox-admin/rules",
    deleteItem: deleteField,
    refetch: refetch,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "mailbox-admin/rules",
    refetch: refetch,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.mailbox.rules.header")} loading={isFetching} />
      <CustomTable
        key={dataTable}
        data={dataTable}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        isFetching={isFetching}
        linkNavigate="edit"
        sort={false}
        handleReorder={handleReorder}
        rowDragging={true}
        order={order}
        setOrder={setOrder}
      />
    </Fragment>
  );
}

export default CustomFields;
