import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const ruleApi = createApi({
  reducerPath: "ruleApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Rule"],
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getRules: build.query({
      query: (queryArgs = {}) => {
        const queryString = qs.stringify(queryArgs);
        return `/api/mailbox/rules?limit=200${queryString ? `&${queryString}` : ""}`;
      },
      transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
      providesTags: (id) => [{ type: "Rule", id }],
    }),
    getFoderSelect: build.query({
      query: (q) => `/api/mailbox/folders/select`,
      keepUnusedDataFor: false,
      providesTags: ["Rule"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getRule: build.query({
      query: (id) => `api/mailbox/rules/${id}`,
      providesTags: (id) => [{ type: "Rule", id }],
      lazy: true,
    }),
    createRule: build.mutation({
      query: (body) => ({
        url: `api/mailbox/rules/new`,
        method: "POST",
        body,
      }),
    }),

    updateRule: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/mailbox/rules/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Rule"],
    }),
    reorderProductFields: build.mutation({
      query: ({ fieldOrder }) => ({
        url: `api/mailbox/rules/reorder`,
        method: "POST",
        body: fieldOrder,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
    }),

    // getProducts: build.query({
    //     query: (q) =>
    //       `/api/mailbox/rules?${qs.stringify({
    //         ...q,
    //         ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
    //       })}`,
    //     keepUnusedDataFor: false,
    //     providesTags: ["Products"],
    //     transformResponse: customTransformResponse,
    //     forceRefetch({ currentArg, previousArg }) {
    //       return currentArg !== previousArg;
    //     },
    //   }),
    // getCompanyFields: build.query({
    //   query: () => "/api/fields/company?limit=200",
    //   transformResponse: customTransformResponse,
    //   keepUnusedDataFor: 120,
    // }),
    // getFieldsList: build.query({
    //   query: ({ query, fieldsType }) =>
    //     `/api/fields/${fieldsType}?${qs.stringify({
    //       ...{ start: query.pageIndex * query.pageSize, limit: query.pageSize },
    //       ...query,
    //     })}`,
    //   forceRefetch({ currentArg, previousArg }) {
    //     return currentArg !== previousArg;
    //   },
    //   keepUnusedDataFor: false,
    //   providesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    // }),
    // updateFields: build.mutation({
    //   query: ({ object = "contact", objectId, field }) => ({
    //     url: `/api/fields/${object}/${objectId}/edit`,
    //     method: "PATCH",
    //     body: field,
    //   }),
    //   invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    // }),
    // deleteFields: build.mutation({
    //   query: ({ object = "lead", objectId }) => ({
    //     url: `/api/fields/${object}/${objectId}/delete`,
    //     method: "DELETE",
    //   }),
    //   onQueryStarted: ({ objectId }, { dispatch }) => {
    //     dispatch(
    //       fieldsApi.util.updateQueryData("getContactFields", "getCompanyFields", undefined, (draft) => {
    //         draft.data = draft.data.filter((field) => field.id !== objectId);
    //         draft.total -= 1;
    //       })
    //     );
    //   },
    //   invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    // }),
    // createField: build.mutation({
    //   query: ({ objectType, field }) => {
    //     delete field.id;
    //     return {
    //       url: `/api/fields/${objectType}/new`,
    //       method: "POST",
    //       body: field,
    //     };
    //   },
    //   invalidatesTags: [{ type: "Fields" }],
    // }),
    // getFieldById: build.query({
    //   query: ({ object = "lead", objectId }) => `/api/fields/${object}/${objectId}`,
    //   transformResponse: customTransformResponse,
    //   forceRefetch({ currentArg, previousArg }) {
    //     return currentArg !== previousArg;
    //   },
    //   keepUnusedDataFor: false,
    //   invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: objectId }],
    // }),
    // reorderFields: build.mutation({
    //   query: ({ type, fieldOrder }) => ({
    //     url: `/api/fields/${type}/reorder`,
    //     method: "POST",
    //     body: fieldOrder,
    //   }),
    //   forceRefetch({ currentArg, previousArg }) {
    //     return currentArg !== previousArg;
    //   },
    //   keepUnusedDataFor: false,
    //   invalidatesTags: (result, error, { objectId }) => [{ type: "Fields", id: fieldOrder.field }],
    // }),
  }),
});

export const {
  useGetRulesQuery,
  useGetFoderSelectQuery,
  useGetRuleQuery,
  useCreateRuleMutation,
  useUpdateRuleMutation,
  useReorderProductFieldsMutation,
  //   useGetCompanyFieldsQuery,
  //   useGetFieldsListQuery,
  //   useDeleteFieldsMutation,
  //   useGetFieldByIdQuery,
  //   useReorderFieldsMutation,
} = ruleApi;
