import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Select } from "../../../reusableComponents/Inputs";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useGetUsersQuery } from "../../../../redux/api/usersApi";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";
import md5 from "blueimp-md5";

const Owners = ({ data, refetch }) => {
  const [hover, setHover] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(data?.contact?.owner?.id);
  const { data: ownerData } = useGetUsersQuery({});

  // Fetch and format owner options
  useEffect(() => {
    if (ownerData?.data && Array.isArray(ownerData?.data)) {
      const formattedOptions = ownerData?.data?.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      setOwnerOptions(formattedOptions);
    }
  }, [ownerData]);

  const [updateOwner] = useUpdateContactsMutation();

  const handleCancel = (e) => {
    e.stopPropagation();
    setIsEdit(false);
    setSelectedOwner(null); // Reset the selection if cancelled
  };

  const handleOwnerChange = (e) => {
    console.log(e);
    setSelectedOwner(e ? e.value : null); // Update selected owner value or set to null if cleared
  };

  const handleOwner = async () => {
    try {
      await updateOwner({
        id: data.contact.id,
        owner: selectedOwner, // If selectedOwner is null, it will send null to the API
      });
      refetch();
      setIsEdit(false); // Close the edit mode on successful save
    } catch (error) {
      console.error("Error updating owner:", error);
    }
  };

  const getGravatarURL = (email, size = 200) => {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  };

  const imageURL = (() => {
    const ownerId = data?.contact?.owner?.id;
    const owner = ownerData?.data?.find((user) => user.id === ownerId);
    return getGravatarURL(owner?.email);
  })();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
        marginTop: "8px",
        width: "100%",
        justifyContent: "flex-start",
        minWidth: "300px",
      }}
      onClick={() => setIsEdit(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {isEdit ? (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}>
          <Select
            options={ownerOptions}
            value={selectedOwner ?? null}
            onChange={handleOwnerChange}
            isClearable // Allow clearing the selection
            placeholder={t("caymland.lead.batch.owner")} // Optional placeholder text
            margin
          />
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <button onClick={handleCancel} style={{ background: "none", border: "none" }}>
              <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
            </button>
            <button onClick={handleOwner} style={{ background: "none", border: "none" }}>
              <AiOutlineSave color="#135F95" size={20} title="Save" />
            </button>
          </div>
        </div>
      ) : data?.contact?.owner?.id ? (
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            margin: 0,
            background: "white",
            color: "#333333",
            borderRadius: "6px",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <img
            src={imageURL}
            alt="Owner"
            style={{
              width: "30px", // Adjusted image width to make it prominent
              height: "30px", // Make the height equal to the width for a square shape
              borderRadius: "50%", // Set radius to match the container
              objectFit: "cover",
            }}
          />
          <span style={{ fontSize: "0.85rem", fontWeight: "500" }}>
            {data?.contact?.owner?.firstName} {data?.contact?.owner?.lastName}
          </span>
        </div>
      ) : (
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            margin: 0,
            background: "white",
            color: "#333333",
            borderRadius: "6px",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <img
            src={imageURL}
            alt="Owner"
            style={{
              width: "30px", // Adjusted image width to make it prominent
              height: "30px", // Make the height equal to the width for a square shape
              borderRadius: "50%", // Set radius to match the container
              objectFit: "cover",
            }}
          />
          <span style={{ fontSize: "0.85rem", fontWeight: "500" }}>{t("Unassigned")}</span>
        </div>
      )}
      {hover && !isEdit && <Edit fontSize="small" style={{ fontSize: "16px" }} />}
    </div>
  );
};

export default Owners;
