import React, { useRef, useState, useEffect } from "react";
import PagesEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetBuilderConfigQuery } from "../../../../../redux/api/usersApi";

const Builder = ({ setModifiedData, modifiedData, apply, setApplyAndCloseTemplateRef }) => {
  const { t } = useTranslation();
  const pagesEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const { data } = useGetBuilderConfigQuery("web");

  const applyAndCloseTemplate = (params) => {
    pagesEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      const encodedHtml = Buffer.from(html).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      pagesEditorRef.current.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setApplyBuilder("savebuilder");
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    if (setApplyAndCloseTemplateRef) {
      setApplyAndCloseTemplateRef(() => applyAndCloseTemplate);
    }
  }, [setApplyAndCloseTemplateRef]);

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [shouldApply]);
  
  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;
  const token = useSelector((state) => state.auth.token);

  const onReady = () => {
    if (data) {
      if (modifiedData?.customJson) {
        try {
          const design = JSON.parse(modifiedData.customJson);

          // Check if the parsed design is empty
          const isEmptyDesign =
            (Array.isArray(design) && design.length === 0) ||
            (typeof design === "object" && Object.keys(design).length === 0);

          if (isEmptyDesign) {
            console.log("customJson is empty, loading default design.");
            // Load an empty or default design
            pagesEditorRef?.current?.editor?.loadDesign({
              body: {
                rows: [],
                values: {},
              },
            });
          } else {
            pagesEditorRef?.current?.editor?.loadDesign(design);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Load an empty or default design in case of a parsing error
          pagesEditorRef?.current?.editor?.loadDesign({
            body: {
              rows: [],
              values: {},
            },
          });
        }
      } else {
        console.log("No custom JSON data to load, loading default design.");
        // Load an empty or default design if no custom JSON is available
        pagesEditorRef?.current?.editor?.loadDesign({
          body: {
            rows: [],
            values: {},
          },
        });
      }
    }
    const editorInstance = pagesEditorRef?.current?.editor;

    if (editorInstance) {
      console.log("Editor is ready");

      // Register the image upload callback (only if not registered already)
      if (!editorInstance.hasRegisteredImageCallback) {
        editorInstance.hasRegisteredImageCallback = true;
        editorInstance.registerCallback("image", (file, done) => {
          console.log("Image upload callback triggered...");
          const data = new FormData();
          data.append("file", file.attachments[0]);

          fetch(`${api}/api/unlayer/image/upload`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `JWT ${token}`, // Add JWT token here
            },
            body: data,
          })
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                return response.json();
              } else {
                const error = new Error(`Failed to upload: ${response.statusText}`);
                error.response = response;
                throw error;
              }
            })
            .then((responseData) => {
              done({
                progress: 100,
                url: responseData?.fileUrl, // Adjust based on your API response
              });
            })
            .catch((error) => {
              console.error("Image upload failed:", error);
              done({
                progress: 0,
                url: null,
                error: "Upload failed. Please try again.",
              });
            });
        });
      }
    }
  };

  useEffect(() => {
    if (modifiedData?.customJson) {
      onReady();
    }
  }, [modifiedData?.customJson, data]);

  return <>{data && <PagesEditor ref={pagesEditorRef} onReady={onReady} minHeight="100%" options={data} />}</>;
};

export default Builder;
