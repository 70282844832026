import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import Form from "react-bootstrap/Form";
import Loading from "../../reusableComponents/loading";
import { Success } from "../../reusableComponents/Alerts";
import Swal from "sweetalert2";
import TabsAddCompany from "./TabsAddCompany";
import CoreTabAddCompany from "./CoreTabAddCompany";
import SocialTabAddCompany from "./SocialTabAddCompany";
import ProfessionalTabAddCompany from "./ProfessionalTabAddCompany";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Text, Select } from "../../reusableComponents/Inputs";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import {
  useAddCompanyMutation,
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
} from "../../../redux/api/companiesApi";
import { useGetCompanyFieldsQuery } from "../../../redux/api/fieldsApi";
import { usersApi } from "../../../redux/api/usersApi";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function AddCompany() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [uniqueFieldGroups, setUniqueFieldGroups] = useState([]);
  const [activeTab, setActiveTab] = useState("core");
  const [customFields, setCustomFields] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [companyFields, setCompanyFields] = useState();
  const [initialValues, setInitialValues] = useState();
  const id = location?.state?.id || location?.state || useParams()?.id;
  const ClonePath = location.pathname.includes("/clone");
  const [backgorundToast, setBackgroundToast] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);

  const q = {};
  const [addCompany, { isLoading: isAddLoading }] = useAddCompanyMutation();
  const [editCompany, { isLoading: isEditLoading }] = useUpdateCompanyMutation();
  const isSaving = isEditLoading || isAddLoading;
  const { data } = useGetCompanyFieldsQuery();
  const { data: company, refetch, isLoading } = useGetCompanyByIdQuery(id, { skip: !id });

  const [triggerUsersQuery] = usersApi.useLazyGetUsersQuery();

  useEffect(() => {
    if (data?.data) {
      setCompanyFields(data.data);
      retrieveGroups();
      retrieveFields();
    }
  }, [data]);

  const retrieveOwners = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerUsersQuery) resolve([]);
      const query = {};
      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerUsersQuery(query);
      if (!response.error) {
        const ownersData = response.data.data;

        const ownersArray = ownersData.map((owners) => ({
          label: owners?.firstName + " " + owners?.lastName,
          value: owners?.id,
        }));
        resolve(ownersArray);
      }
    });

  const retrieveFields = async () => {
    let orderFields = [...data.data];

    if (orderFields !== null) {
      setCustomFields(
        orderFields.sort((a, b) => a.order - b.order).filter((x) => x.isVisible === true || x.isRequired === true)
      );
    } else {
      setCustomFields([]);
    }
  };

  const retrieveGroups = () => {
    let customFields = [...data.data];
    let groups = customFields.map((x) => {
      if (x.isPublished === true || x.isRequired === true) {
        return x.group;
      }
    });
    let uniqueGroups = [...new Set(groups.filter(Boolean))];

    const staticTabs = ["core", "professional", "social"];
    const orderedTabs = staticTabs.filter((tab) => uniqueGroups.includes(tab));
    const otherTabs = uniqueGroups.filter((tab) => !staticTabs.includes(tab));
    uniqueGroups = [...orderedTabs, ...otherTabs];

    setUniqueFieldGroups(uniqueGroups);
  };

  const navBack = () => {
    navigate("/companies");
  };

  useEffect(() => {
    if (company?.data && id) {
      const mergedData = {
        ...company?.data?.fields?.all,
        score: company?.data?.score,
        owner: company?.data?.owner?.id,
      };
      reset(mergedData);
      setInitialValues(mergedData);
    }
  }, [company]);

  const requiredFields = customFields.filter((item) => item.isRequired);

  const generateYupSchema = (fields) => {
    const schemaFields = fields.reduce((acc, field) => {
      let validator;
      switch (field.type) {
        case "boolean":
          validator = yup.number().required("This field is required");
          break;
        case "email":
          validator = yup
            .string()
            .email("A valid email is required.")
            .required("This field is required!")
            .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\d*$/, {
              message: "Invalid email format",
            });
          break;
        default:
          validator = yup.string().required("This field is required.");
      }
      acc[field.alias] = validator;
      return acc;
    }, {});

    return yup.object().shape(schemaFields);
  };

  const validationSchema = generateYupSchema(requiredFields);

  //USE-FROM
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const formValues = watch(); // Watches all fields
  useEffect(() => {
    // Assuming `initialValues` is an object that contains the original form data
    const isChanged = JSON.stringify(formValues) !== JSON.stringify(initialValues);
    setIsFormChanged(isChanged);
  }, [formValues]);
  console.log(company);

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      console.error(errors);
      if (errors) {
        setShow(true);
        setMessage(t("caymland.core.error.not.valid"));
        setBackgroundToast("2");
        setTimeout(() => {
          setMessage("");
          setShow(false);
          setBackgroundToast("1");
        }, 3000);
      }
    }
  }, [errors]);
  useEffect(() => {}, []);

  const handleAddCompany = async (data, close = false) => {
    const dataToSend = { ...data };
    const response =
      _.has(dataToSend, "id") && !ClonePath ? await editCompany(dataToSend) : await addCompany(dataToSend);
    console.log(response);
    if (!response?.error) {
      Success(
        `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
          type: id && !ClonePath ? "edited" : "created",
        })}`
      ).then((result) => {
        if (result.isConfirmed) {
          navigate(`/companies/${close ? "view" : "edit"}/${response.data.company.id}`);
        }
      });
      if (id) refetch();
      reset(response.data.company.fields.all);
    } else {
      setShow(true);
      setBackgroundToast("2");
      setMessage(response.error.data.errors[0].message);
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3000);
    }
  };

  function scrollToTab(tabId, tabTitle) {
    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: "smooth" });
    }
    setActiveTab(tabTitle);
  }

  const DetailTabsCard = () => {
    return uniqueFieldGroups ? (
      <div className="flex items-center justify-between">
        <Nav className="nav-primary w-full" tabs style={{ borderBottom: "1px solid #135f9530" }}>
          {uniqueFieldGroups?.map((tabTitle, index) => (
            <NavItem key={tabTitle}>
              <NavLink
                href="#"
                className={tabTitle === activeTab ? "active" : ""}
                onClick={() => scrollToTab(`tab-${index}`, tabTitle)}
                style={{
                  color: "#313131",
                  textTransform: "capitalize",
                }}
              >
                {tabTitle.replace(/[^a-zA-Z0-9]/g, " ")}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    ) : (
      <div style={{ display: "grid", placeItems: "center" }}>
        <Loading />
      </div>
    );
  };

  const handleCancel = () => {
    if (isFormChanged && id) {
      Swal.fire({
        title: t("caymland.badge_contact.form.confirmdelete"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          navBack();
        } else {
          return;
        }
      });
    } else {
      navBack();
    }
  };

  const buttons = [
    {
      name: "Close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel(),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleAddCompany(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleAddCompany(data)),
    },
  ];

  return (
    <>
      <Header
        buttons={buttons}
        title={`${t("caymland.core.form.new")} ${t("caymland.company.company")}`}
        alertMessage={message}
        showAlert={show}
        loading={isSaving}
        background={backgorundToast}
      />
      <Wrapper
        childrenClassOne={"wrapper-childTwo20"}
        childrenOne={
          <Form className="tw-bg-white tw-p-4">
            <Controller
              control={control}
              name="score"
              render={({ field: { name, onChange, value } }) => (
                <Text
                  label="Score"
                  name={name}
                  type="number"
                  value={value}
                  onChange={onChange}
                  errors={errors && errors[name]}
                />
              )}
            />
            <Controller
              control={control}
              name="owner"
              render={({ field: { name, onChange, value } }) => (
                <Select
                  name={name}
                  label={t("caymland.lead.company.field.owner")}
                  options={retrieveOwners}
                  isClearable={false}
                  value={value}
                  onChange={(so) => onChange(so.value)}
                />
              )}
            />
          </Form>
        }
        childrenClassTwo={"wrapper-childOne80"}
        childrenTwo={
          <Card style={{ boxShadow: "unset" }}>
            <CardBody style={{ padding: "0" }}>
              <DetailTabsCard />
              {activeTab === "core" ? (
                <CoreTabAddCompany
                  companyFields={companyFields}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                />
              ) : activeTab === "professional" ? (
                <ProfessionalTabAddCompany
                  companyFields={companyFields}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                />
              ) : activeTab === "zusatzinformationen" ? (
                <SocialTabAddCompany
                  companyFields={companyFields}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                />
              ) : (
                uniqueFieldGroups.includes(activeTab) && (
                  <TabsAddCompany
                    activeTab={activeTab}
                    companyFields={companyFields}
                    control={control}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                  />
                )
              )}
            </CardBody>
          </Card>
        }
      />
    </>
  );
}

export default AddCompany;
