import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { t } from "i18next";
import {
  useAddFolderTemplatesMutation,
  useDeleteFolderTemplatesMutation,
  useDeleteTemplateMutation,
  useGetFolderTempletesQuery,
  useGetTemplatesQuery,
  useRemoveTemplatesToFoldersMutation,
  useUpdateFolderTemplatesMutation,
  useUpdateTemplatesMutation,
  useUpdateTemplatesToFoldersMutation,
} from "../../../redux/api/templatesApi";

function Templates(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const name = "BTemplates";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem("orderByBTemplates") ? sessionStorage.getItem("orderByBTemplates") : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "brochures.templates",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "brochures.templates",
  };

  const clientSchema = yup.object({
    name: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const handleTogglePublished = async (data) => {
    const updatedData = { isPublished: !data.isPublished };
    await updateTemplates({ id: data.id, ...updatedData });
    await refetchTableData();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.core.name"),
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          handleTogglePublished={handleTogglePublished}
          link="products"
          permissionKey="brochures:templates"
          view={true}
        />
      ),
      size: 100,
      enableColumnFilters: false,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const { data: templatesData, isFetching: isFetching, refetch: refetchTableData } = useGetTemplatesQuery(query);
  const { data: folderTempletesData, refetch } = useGetFolderTempletesQuery();

  const [deleteTemplates] = useDeleteTemplateMutation();
  const [updateTemplates] = useUpdateTemplatesMutation(id);

  const [addFolder] = useAddFolderTemplatesMutation();
  const [updateFolder] = useUpdateFolderTemplatesMutation();
  const [deleteFolder] = useDeleteFolderTemplatesMutation();

  const [updateTemplatesToFolder] = useUpdateTemplatesToFoldersMutation();
  const [removeTemplatesFromFolder] = useRemoveTemplatesToFoldersMutation();

  const actionButtons = ActionButtons({
    link: "templates",
    deleteItem: deleteTemplates,
    refetch: refetchTableData,
    removeItemFromFolder: removeTemplatesFromFolder,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    permissonKey: "brochures:templates",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "brochures/templates",
    refetch: refetchTableData,
    permissonKey: "brochures:templates",
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "addnew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={17} />,
            onClick: () => navigate("/templates/new"),
          },
        ]}
        title={"Template"}
      />
      <DragAndDropTable
        filteredData={templatesData?.data}
        folderData={folderTempletesData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={<CampaignForm control={control} />}
        handleSubmit={handleSubmit}
        reset={reset}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateTemplatesToFolder}
        linkNavigate="edit"
        toolbarActions={{
          select: true,
        }}
      />
    </Fragment>
  );
}

export default Templates;
