import React, { useEffect, useRef, useState } from "react";
import { Select } from "../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../reusableComponents/retriveCategoriesByBundle";
import { categoriesApi } from "../../../../redux/api";
import { useAddContactCategoryMutation } from "../../../../redux/api/contactsApi";
import { useParams } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ModalGenerator from "../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import { useAddCategoryMutation } from "../../../../redux/api/categoriesApi";
import { FaRegTimesCircle } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";

const Categories = ({
  replaceCategories,
  categoriesFields,
  setShow,
  setMessage,
  setBackgroundToast,
  normal = true,
  onSave,
  setPrefCategories = () => {},
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(!normal);
  const [editCategories, setEditCategories] = useState([]);
  const [hover, setHover] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "global",
  });
  const [newItem, setNewItem] = useState();
  const [addCategory] = useAddCategoryMutation();
  const editRef = useRef(null);

  const { id } = useParams();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesQuery();
  const [updateContact] = useAddContactCategoryMutation();

  useEffect(() => {
    if (Array.isArray(categoriesFields) && categoriesFields.length > 0) {
      const formattedCategories = categoriesFields?.map((category) => ({
        title: category.title,
        id: parseInt(category.id),
        color: category.color,
      }));
      setEditCategories(formattedCategories);
      setPrefCategories(formattedCategories);
    }
  }, [categoriesFields]);

  useEffect(() => {
    if (newItem) {
      const updatedCategories = [...editCategories, newItem];
      setEditCategories(updatedCategories);
    }
  }, [newItem]);

  const handleChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.id === "create_new")) {
      setModalOptions({
        ...modalOptions,
        isOpen: true,
      });
    } else {
      setEditCategories(selectedOptions);
      setPrefCategories(selectedOptions);
    }
  };

  const updateCategory = async () => {
    const categories = editCategories?.map((category) => category.id) || [];

    try {
      const payload = { categories };
      const response = await updateContact({ id, body: payload });
      if (!response.errors) {
        replaceCategories(editCategories);
        setEdit(false);
        setShow(true);
        setMessage(t("caymland.core.setmessage.categories"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
        if (onSave) onSave();
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      console.error("Error updating stage:", error);
    }
  };

  const isWhite = (hexColor) => {
    if (!hexColor) {
      return;
    }
    const whiteLimit = 200;
    if (hexColor.startsWith("#")) hexColor = hexColor.slice(1); // Remove "#" if present
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    return r > whiteLimit && g > whiteLimit && b > whiteLimit;
  };

  useEffect(() => {
    if (onSave) {
      onSave.current = updateCategory;
    }
  }, [onSave, updateCategory]);

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
        type="contacts"
      />
      <div
        ref={editRef}
        className={`cursor-pointer flex items-center mt-10 gap-5px tw-flex-wrap tw-max-w-[90%] tw-p-4`}
        style={{ borderRadius: "6px", maxWidth: "100%" }}
        onClick={() => normal && setEdit(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {edit ? (
          <div className="flex items-center w-full">
            <Select
              isMulti
              isSearchable
              margin
              className="w-full"
              bundle="contacts"
              options={retrieveCategories}
              value={editCategories}
              onChange={handleChange}
              trigger={triggerCategories}
              labelField="title"
              valueField="id"
              isCreatable
            />
            {normal && (
              <group className="flex gap-5px items-center ">
                <button
                  className="normal-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEdit(false);
                    setEditCategories(categoriesFields);
                  }}
                >
                  <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
                </button>
                <button className="normal-btn" onClick={updateCategory}>
                  <AiOutlineSave color="#135F95" size={20} title="Save" />
                </button>
              </group>
            )}
          </div>
        ) : (
          <>
            <div className="tw-flex tw-flex-wrap tw-gap-[8px] tw-space-between tw-max-w-[90%]">
              {categoriesFields?.length > 0 ? (
                categoriesFields?.map((category) => (
                  <div className="tw-flex tw-flex-wrap tw-gap-[8px]">
                    <span
                      className=""
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        alignItems: "center",
                        fontSize: "0.85rem",
                        fontWeight: "500",
                        color: isWhite(category?.color) ? "black" : `${category?.color}`,
                        borderRadius: "6px",
                        overflow: "hidden",
                        cursor: "pointer",
                        height: "30px",
                        lineHeight: "1.5rem",
                        padding: "0",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: `${category?.color}`,
                          color: isWhite(category?.color) ? "black" : "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0 0.8rem",
                          width: "40px",
                          height: "100%",
                          borderTopLeftRadius: "6px",
                          borderBottomLeftRadius: "6px",
                        }}
                      >
                        <i style={{ fontSize: "1rem", color: isWhite(category?.color) ? "black" : "white" }}>
                          <BiCategory size={20} />
                        </i>
                      </span>
                      <span
                        style={{
                          padding: "0 1rem",
                          background: "#e0e0e0",
                          color: "#333333",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          borderTopRightRadius: "6px",
                          borderBottomRightRadius: "6px",
                        }}
                      >
                        {category?.title}
                      </span>
                    </span>
                  </div>
                ))
              ) : (
                <span> {t("caymland.contact.add.categories")}</span>
              )}
            </div>
          </>
        )}
        <div> {hover && !edit && normal && <Edit fontSize="small" style={{ marginLeft: "auto" }} />} </div>
      </div>
    </>
  );
};

export default Categories;
