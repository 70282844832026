import React, { Fragment, useEffect, useMemo, useState } from "react";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { t } from "i18next";
import {
  useAddFolderSerialLettersMutation,
  useDeleteFolderStagesMutation,
  useDeleteSerialLetterMutation,
  useGetFolderSerialLettersQuery,
  useGetSerialLettersQuery,
  useUpdateFolderStagesMutation,
  useUpdateSerialLettersToFoldersMutation,
} from "../../../redux/api/serialLettersApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import { WebHookSearchHelper } from "../../reusableComponents/HelperTexts/SearchHelper";
const SerialLetters = () => {
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const name = "SerialLetters";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "serialletters",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "serialletters",
  };

  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const body = [<CampaignForm control={control} />];
  const { data: serialData, isFetching, refetch: refetchTableData } = useGetSerialLettersQuery(query);
  const { data: folderData, refetch } = useGetFolderSerialLettersQuery();

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      size: 150,
      accessorFn: (row) => row.name,
      header: t("caymland.user.account.permissions.editname"),
      Cell: ({ cell }) => cell.row.original.name,
      enableColumnActions: false,
    },
    {
      accessorKey: "Uploaded",
      size: 150,
      accessorFn: (row) => {
        const url = row.source;
        return url ? url.substring(url.lastIndexOf("/") + 1) : "";
      },
      header: t("caymland.plugin.badge.generator.form.uploaded_source_pdf"),
      enableColumnActions: false,
      Cell: ({ cell }) => {
        const url = cell?.row?.original?.source;
        return url ? url.substring(url.lastIndexOf("/") + 1) : "";
      },
    },
    {
      accessorKey: "name2",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "name2",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "name2",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "name2",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "name2",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);
  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/serial-letters/new"),
    },
  ];
  const [deleteSerialLetter] = useDeleteSerialLetterMutation();
  const [addFolder] = useAddFolderSerialLettersMutation();
  const [updateFolder] = useUpdateFolderStagesMutation();
  const [deleteFolder] = useDeleteFolderStagesMutation();
  const [updateLetterToFolder] = useUpdateSerialLettersToFoldersMutation();

  const actionButtons = ActionButtons({
    link: "serial-letters",
    refetch: refetchTableData,
    deleteItem: deleteSerialLetter,
    folderId: activeRowId,
    permissionKey: "mailbox:template",
  });
  const actionBatchButtons = ActionBatchButtons({
    link: "serialletters",
    refetch: refetchTableData,
    permissionKey: "mailbox:template",
  });
  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.plugin.badge.generator.index")} />
      <DragAndDropTable
        data={serialData}
        folderData={folderData}
        folderColumns={folderColumns}
        columns={columns}
        isFetching={isFetching}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetchTableData={refetchTableData}
        actionBatchButtons={actionBatchButtons}
        addFolder={addFolder}
        body={body}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        refetch={refetch}
        query={query}
        reset={reset}
        setQuery={setQuery}
        handleSubmit={handleSubmit}
        linkNavigate="edit"
        actionButtons={actionButtons}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        updateCampaignsToFolder={updateLetterToFolder}
        searchHelperBody={<WebHookSearchHelper />}
      />
    </Fragment>
  );
};

export default SerialLetters;
