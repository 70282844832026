import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { FiUsers, FiList } from "react-icons/fi";
import { TbArrowsRightLeft, TbPlug } from "react-icons/tb";
import { BsTag } from "react-icons/bs";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import { TbReportAnalytics } from "react-icons/tb";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";

import { t } from "i18next";

export const MENUITEMS = [
  {
    title: t("caymland.dashboard.menu.index"),
    icon: DashboardOutlinedIcon,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/dashboard",
    permissionKey: "api:access",
  },
  {
    title: "caymland.contact.segments.fields.contacts",
    icon: PeopleAltOutlinedIcon,
    type: "link",
    path: "/contacts",
    permissionKey: "lead:leads",
    active: false,
  },
  {
    title: "caymland.companies.menu.index",
    icon: BusinessOutlinedIcon,
    type: "link",
    path: "/companies",
    permissionKey: "lead:leads",
    active: false,
  },
  {
    title: "caymland.lead.preferred.segments",
    icon: FilterAltOutlinedIcon,
    type: "link",
    permissionKey: "lead:leads",
    path: "/segments",
    active: false,
  },
  {
    title: "caymland.plugin.badge.generator.index",
    icon: LocalPostOfficeOutlinedIcon,
    type: "sub",
    permissionKey: ["mailbox:template", "lead:exports"],
    pluginName: "BadgeGenerator",
    active: false,
    children: [
      {
        path: "/serial-letters",
        title: "caymland.plugin.badge.generator",
        type: "link",
        permissionKey: "mailbox:template",
      },
      {
        path: "/multiple-exports",
        title: "caymland.plugin.badge.generator.segments",
        type: "link",
        permissionKey: "lead:exports",
      },
    ],
  },
  {
    title: "caymland.core.components",
    icon: ViewInArOutlinedIcon,
    type: "sub",
    permissionKey: ["asset:assets", "form:forms", "page:pages", "dynamiccontent:dynamiccontents"],
    active: false,
    children: [
      {
        path: "/assets",
        title: "caymland.asset.assets",
        type: "link",
        permissionKey: "asset:assets",
      },
      {
        path: "/forms",
        title: "caymland.form.forms",
        type: "link",
        permissionKey: "form:forms",
      },
      {
        path: "/pages",
        title: "caymland.page.pages",
        type: "link",
        permissionKey: "page:pages",
      },
      {
        path: "/dwc",
        title: "caymland.core.dynamicContent",
        type: "link",
        permissionKey: "dynamiccontent:dynamiccontents",
      },
    ],
  },
  {
    title: "caymland.campaign.campaigns",
    icon: DisplaySettingsOutlinedIcon,
    type: "link",
    path: "/campaigns",
    active: false,
    permissionKey: "campaign:campaigns",
  },

  {
    title: "caymland.brochures.menu.orders",
    icon: ArticleOutlinedIcon,
    type: "link",
    path: "/orders",
    permissionKey: "brochures:orders",
    pluginName: "Brochures",
    active: false,
  },
  {
    title: "caymland.core.channels",
    icon: ChatBubbleOutlineOutlinedIcon,
    type: "sub",
    permissionKey: ["email:emails", "sms:smses", "focus:items"],
    active: false,
    children: [
      {
        path: "/emails",
        title: "E-Mails",
        type: "link",
        permissionKey: "email:emails",
      },
      {
        path: "/sms",
        title: "SMS",
        type: "link",
        permissionKey: "sms:smses",
        pluginName: "SMS",
      },
      {
        path: "/focus",
        title: "caymland.focus",
        type: "link",
        permissionKey: "focus:items",
      },
    ],
  },
  {
    title: "caymland.lead.field.points",
    icon: GpsFixedOutlinedIcon,
    type: "sub",
    permissionKey: ["point:points", "point:triggers"],
    active: false,
    children: [
      {
        path: "/points",
        title: "caymland.point.menu.index",
        type: "link",
        permissionKey: "point:points",
      },
      {
        path: "/triggers",
        title: "caymland.point.trigger.menu.index",
        type: "link",
        permissionKey: "point:triggers",
      },
    ],
  },
  {
    title: "caymland.stages.menu.root",
    icon: RouteOutlinedIcon,
    type: "link",
    path: "/stages",
    active: false,
    permissionKey: "stage:stages",
  },
  {
    title: "caymland.report.reports",
    icon: AssessmentOutlinedIcon,
    type: "link",
    path: "/reports",
    active: false,
    permissionKey: "report:reports",
  },
  {
    title: "caymland.mailbox.menu",
    icon: EmailOutlinedIcon,
    type: "link",
    path: "/mailbox",
    active: false,
    permissionKey: "mailbox:items",
    pluginName: "Mailbox",
  },
];

export const SETTINGSMENU = [
  {
    title: "caymland.category.header.index",
    icon: FolderCopyOutlinedIcon,
    type: "link",
    path: "/categories",
    permissionKey: "category:categories",
  },
  {
    title: "caymland.user.users",
    icon: PeopleAltOutlinedIcon,
    type: "link",
    path: "/users",
    permissionKey: "user:users",
  },
  {
    title: "caymland.webhook.webhooks",
    icon: WebhookOutlinedIcon,
    type: "link",
    path: "/webhooks",
    permissionKey: "webhook:webhooks",
  },
  {
    title: "caymland.lead.field.header.index",
    type: "link",
    icon: FormatListBulletedOutlinedIcon,
    path: "/contacts/fields",
    permissionKey: "lead:fields",
  },
  {
    title: "caymland.tags_editor",
    icon: LocalOfferOutlinedIcon,
    type: "link",
    path: "/tags",
    permissionKey: "tageditor:items",
    pluginName: "Tags",
  },
  {
    title: "caymland.storage.purpose",
    icon: SourceOutlinedIcon,
    type: "link",
    path: "/purposes",
    permissionKey: "lead:leads",
    pluginName: "StoragePurpose",
  },
  {
    title: "caymland.brochures",
    icon: ArticleOutlinedIcon,
    type: "sub",
    active: false,
    permissionKey: ["brochures:orders", "brochures:products", "brochures:templates"],
    pluginName: "Brochures",
    children: [
      {
        path: "/products",
        title: "caymland.brochures.menu.brochures",
        type: "link",
        permissionKey: "brochures:products",
      },
      {
        path: "/templates",
        title: "caymland.brochures.menu.assets",
        type: "link",
        permissionKey: "brochures:templates",
      },
    ],
  },
  {
    title: "caymland.mailbox.menu.admin.index",
    icon: ContactMailOutlinedIcon,
    pluginName: "Mailbox",
    type: "sub",
    active: false,
    children: [
      {
        path: "/mailbox-admin/template",
        title: "caymland.mailbox.templates.index",
        type: "link",
      },
      {
        path: "/mailbox-admin/classification",
        title: "caymland.mailbox.classification.index",
        type: "link",
      },
      {
        path: "/mailbox-admin/rules",
        title: "caymland.mailbox.rules.index",
        type: "link",
      },
    ],
  },
];
