import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MRT_CustomRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomRowsAction";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { CategoryComponent } from "../../reusableComponents/tableComponents/CategoryComponent";
import { MRT_CustomHeaderRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomHeaderRowsAction";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import {
  useAddFolderSegmentsMutation,
  useDeleteFolderSegmentsMutation,
  useDeleteSegmentMutation,
  useGetFolderSegmentsQuery,
  useGetRebuildSegmentsMutation,
  useGetSegmentsQuery,
  useRemoveSegmentsToFoldersMutation,
  useUpdateFolderSegmentsMutation,
  useUpdateSegmentsMutation,
  useUpdateSegmentsToFoldersMutation,
} from "../../../redux/api/segmentsApi";
import { TbRefresh } from "react-icons/tb";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { useTranslation } from "react-i18next";
import { SegmentSearchHelper } from "../../reusableComponents/HelperTexts/SearchHelper";

function Segments(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const [localLoading, setLocalLoading] = useState(-1);
  const name = "Segments";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });
  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "segments",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "segments",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { ...data, isPublished: !data.isPublished };
    await updateSegment(updatedData);
    await refetchTableData();
    setLocalLoading(-1);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) => row.name,
        header: t("caymland.core.name"),
        size: 150,
        enableColumnFilters: false,
        enableSorting: false,
        Cell: ({ cell }) => (
          <LinkComponent
            data={cell.row.original}
            loading={localLoading}
            handleTogglePublished={handleTogglePublished}
            link="segments"
            permissionKey="lead:lists"
            view={true}
          />
        ),
      },
      {
        accessorKey: "contacts",
        header: t("caymland.company.contacts.label"),
        enableColumnFilters: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/contacts`, {
                  state: { search: `segment: ${cell?.row?.original?.alias}` },
                });
              }}
              className="result-component"
            >
              {cell?.row?.original.isActive ? (
                <h1 className="rebuild-progress">
                  {t("caymland.core.update.inprogress")}
                  <span className="dot-one"> .</span>
                  <span className="dot-two"> .</span>
                  <span className="dot-three"> .</span>
                </h1>
              ) : (
                `${t("caymland.core.permissions.view")} ${cell?.row?.original?.leadCount || 0} ${t(
                  "caymland.lead.leads"
                )}`
              )}
            </div>
          );
        },
      },
    ],

    [t]
  );

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);
  const body = [<CampaignForm control={control} />];

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/segments/new", { state: activeRowId }),
    },
  ];

  const successMessage = "Campaign updated successfully!";
  const failMessage = "There was an error updating the campaign!";
  const showMessage = showAlert ? alertMessage : success ? successMessage : fail ? failMessage : "";

  const {
    data: segmentsData,
    isLoading: segmentsIsLoading,
    isFetching: segmentsIsFetching,
    refetch: refetchTableData,
  } = useGetSegmentsQuery(query);

  const {
    data: folderSegmentsData,
    isLoading: isFolderLoading,
    isFetching: isFolderFetching,
    refetch,
  } = useGetFolderSegmentsQuery();

  const [deleteSegment] = useDeleteSegmentMutation();
  const [updateSegment] = useUpdateSegmentsMutation();
  const [addFolder] = useAddFolderSegmentsMutation();
  const [updateFolder] = useUpdateFolderSegmentsMutation();
  const [deleteFolder] = useDeleteFolderSegmentsMutation();
  const [updateSegmentsToFolder] = useUpdateSegmentsToFoldersMutation();
  const [removeSegmentsFromFolder] = useRemoveSegmentsToFoldersMutation();
  const [rebuildSegment] = useGetRebuildSegmentsMutation();

  const actionButtons = ActionButtons({
    link: "segments",
    rebuildComponent: rebuildSegment,
    removeItemFromFolder: removeSegmentsFromFolder,
    command: "caymland:segments:update",
    runCommand: true,
    deleteItem: deleteSegment,
    refetch: refetchTableData,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    setShowAlert: setShowAlert,
    setAlertMessage: setAlertMessage,
    permissionKey: "lead:lists",
  });
  const actionBatchButtons = ActionBatchButtons({
    link: "segments",
    refetch: refetchTableData,
    permissionKey: "lead:lists",
  });

  return (
    <Fragment>
      <Header
        buttons={buttons}
        title={t("caymland.lead.preferred.segments")}
        alertMessage={alertMessage}
        showAlert={showAlert}
      />
      <DragAndDropTable
        data={segmentsData}
        folderData={folderSegmentsData}
        columns={columns}
        isFetching={segmentsIsFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateSegmentsToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        searchHelperBody={<SegmentSearchHelper />}
      />
    </Fragment>
  );
}

export default Segments;
