import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import {
  useAddFolderCampaignsMutation,
  useDeleteCampaignMutation,
  useDeleteFolderCampaignsMutation,
  useGetCampaignsQuery,
  useGetFolderCampaignsQuery,
  useGetRunCampaignsMutation,
  useGetSelectSegmentsQuery,
  useRemoveCampaignsToFoldersMutation,
  useUpdateCampaignsMutation,
  useUpdateCampaignsToFoldersMutation,
  useUpdateFolderCampaignsMutation,
} from "../../../redux/api/campaignsApi";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { CategoryComponent } from "../../reusableComponents/tableComponents/CategoryComponent";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { t } from "i18next";

function Campaigns(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [localLoading, setLocalLoading] = useState(-1);
  const [activeRowId, setActiveRowId] = useState(null);
  const [editingFolder, setEditingFolder] = useState();
  const [backgorundToast, setBackgroundToast] = useState("");
  const name = "Campaigns";

  const [query, setQuery] = useState(() => {
    if (location.state && location.state.search) {
      return {
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
        orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
        name: name,
        where: [],
        search: location?.state?.search
          ? location.state.search
          : sessionStorage.getItem(`search${name}`)
          ? sessionStorage.getItem(`search${name}`)
          : "",
      };
    } else {
      return {
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
        orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
        name: name,
        search: location?.state?.search
          ? location.state.search
          : sessionStorage.getItem(`search${name}`)
          ? sessionStorage.getItem(`search${name}`)
          : "",
        where: [],
      };
    }
  });

  const { data: campaignsData, isFetching: isFetching, refetch: refetchTableData } = useGetCampaignsQuery(query);
  const { data: folderCampaignsData, refetch } = useGetFolderCampaignsQuery();
  const { data: selectSegments } = useGetSelectSegmentsQuery();

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "campaigns",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "campaigns",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { ...data, isPublished: !data.isPublished };
    await updateCampaign(updatedData);
    await refetchTableData();
    setLocalLoading(-1);
  };
  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.user.account.permissions.editname"),
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="campaigns"
          permissionKey="campaign:campagins"
          view={true}
        />
      ),
      size: 150,
      enableColumnFilters: false,
    },
    {
      accessorKey: "category",
      accessorFn: (row) => (row.category?.title ? row.category?.title : "Uncategorized"),
      header: t("caymland.lead.lead.category"),
      size: 150,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "name", //access nested data with dot notation
      header: `${"caymland.mailbox.rules.folder"} ${t("caymland.user.account.permissions.editname")}`,
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);
  const body = [<CampaignForm control={control} />];

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/campaigns/new"),
    },
  ];

  const listCategory = (type) => {
    if (type) {
      setQuery((prev) => ({
        ...prev,
        where: [
          {
            col: "l.id",
            expr: "in",
            val: type,
          },
        ],
      }));
    } else {
      setQuery((prev) => ({
        ...prev,
        where: [],
      }));
    }
  };
  const [deleteCampaign] = useDeleteCampaignMutation();
  const [updateCampaign] = useUpdateCampaignsMutation();
  const [addFolder] = useAddFolderCampaignsMutation();
  const [updateFolder] = useUpdateFolderCampaignsMutation();
  const [deleteFolder] = useDeleteFolderCampaignsMutation();
  const [updateCampaignsToFolder] = useUpdateCampaignsToFoldersMutation();
  const [rebuildCampaign] = useGetRunCampaignsMutation();
  const [removeCampaignsFromFolder] = useRemoveCampaignsToFoldersMutation();

  const actionButtons = ActionButtons({
    link: "campaigns",
    name: "campaign",
    deleteItem: deleteCampaign,
    refetch: refetchTableData,
    rebuildComponent: rebuildCampaign,
    removeItemFromFolder: removeCampaignsFromFolder,
    runCommand: true,
    command: ["caymland:campaigns:rebuild", "caymland:campaigns:trigger"],
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    setAlertMessage: setAlertMessage,
    setShowAlert: setShowAlert,
    setBackgroundToast: setBackgroundToast,
    permissionKey: "campaign:campaigns",
  });
  const actionBatchButtons = ActionBatchButtons({
    link: "campaigns",
    refetch: refetchTableData,
    permissionKey: "campaign:campagins",
  });

  const options = Object.entries(selectSegments?.lists || []).map(([_, { id, name }]) => ({
    value: id,
    label: name,
  }));
  const selectProps = {
    data: options,
    onChange: listCategory,
    value: query?.where && query?.where[0]?.val,
    title: t("caymland.campaign.campaigns"),
  };

  return (
    <Fragment>
      <Header
        buttons={buttons}
        title={t("caymland.campaign.campaigns")}
        alertMessage={alertMessage}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        background={backgorundToast}
      />
      <DragAndDropTable
        data={campaignsData}
        folderData={folderCampaignsData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateCampaignsToFolder}
        link="campaigns"
        toolbarActions={{
          select: true,
        }}
        selectProps={selectProps}
        searchHelper={false}
      />
    </Fragment>
  );
}

export default Campaigns;
