import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import { Text, TextArea, Select as ReusableSelect } from "../../../../reusableComponents/Inputs";
import { Label } from "../../../../reusableComponents/Inputs/index";
import {
  pointsApi,
  useGetPointActionEventsQuery,
  useGetPointActionTypeQuery,
} from "../../../../../redux/api/pointsApi";
import { t } from "i18next";

function Details({ errors, setErrors, modifiedData, setModifiedData }) {
  const [viewActionTypes, setViewActionTypes] = useState(false);
  const [urlTypes, setUrlTypes] = useState(false);
  const [pointActionTypes, setPointActionTypes] = useState({});
  const [limitSelected, setLimitSelected] = useState({});
  const [objectKey, setObjectKey] = useState();
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredOptionsState, setFilteredOptionsState] = useState();
  const [tootlip, setToltip] = useState("");
  const [types, setTypes] = useState({});

  const [trigger] = pointsApi.useLazyGetPointActionTypeQuery();
  const [triggerPointActionEvents] = pointsApi.useLazyGetPointActionEventsQuery();
  const { data: pointActionEvents } = useGetPointActionEventsQuery({
    type: modifiedData?.type,
  });

  const retrieveEvents = async () => {
    if (pointActionEvents?.modalData) {
      const key = Object.keys(pointActionEvents?.modalData?.children)[0];
      setObjectKey(key);
      setPointActionTypes(pointActionEvents?.modalData?.children[key]);
      setToltip(pointActionEvents?.modalData?.children[key]?.attr?.tooltip);
    }
  };

  useEffect(() => {
    if (modifiedData?.type) {
      retrieveEvents();
    }
  }, [pointActionEvents]);

  const retrieveActionTypes = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!trigger) resolve([]);

      const types = await trigger().unwrap();
      setTypes(types);

      const filteredTypes = types?.map((group) => ({
        ...group,
        options: group.options.filter((option) => option?.label?.toLowerCase().includes(inputValue?.toLowerCase())),
      }));

      resolve(filteredTypes);
    });

  // const retrieveActionTypes = async () => {
  //   let types = await trigger();
  //   setTypes(types?.data)
  //   return types.data
  // };

  // useEffect(()=>{
  //   retrieveActionTypes()
  // },[pointActionTypesData, trigger])

  const HDM = [
    { value: "d", label: t("caymland.core.time.days") },
    { value: "H", label: t("caymland.core.time.hours") },
    { value: "i", label: t("caymland.core.time.minutes") },
  ];

  useEffect(() => {
    if (objectKey && limitSelected) {
      let propertiesArray = Array.isArray(modifiedData.properties[objectKey]) ? modifiedData.properties[objectKey] : [];
      let newPropertiesSet = new Set([...propertiesArray, ...propertiesArray.map(String)]);

      if (Array.isArray(limitSelected)) {
        let result = limitSelected.flatMap((obj) => {
          let filteredOptions = obj.options.filter((option) => {
            return newPropertiesSet.has(option.value);
          });
          return filteredOptions;
        });
        setFilteredOptionsState(result);
      } else {
      }
    }
  }, [objectKey, limitSelected]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!pointActionEvents?.error) {
          if (pointActionEvents?.modalData) {
            const key = Object.keys(pointActionEvents?.modalData?.children)[0];
            setObjectKey(key);
            setPointActionTypes(pointActionEvents?.modalData?.children[key]);
            // setViewActionTypes(true);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (modifiedData?.type !== "url.hit" && modifiedData?.type !== null) {
      setViewActionTypes(true);
      setUrlTypes(false);
    } else if (modifiedData?.type === "url.hit") {
      setUrlTypes(true);
      setViewActionTypes(false);
    } else {
      setUrlTypes(false);
      setViewActionTypes(false);
    }
    fetchData();
  }, [modifiedData?.type]);

  useEffect(() => {
    if (pointActionTypes?.choices) {
      const transformedChoices = transformChoices(pointActionTypes?.choices);
      setLimitSelected(transformedChoices);
    }
  }, [pointActionTypes.choices, selectedType]);

  useEffect(() => {
    if (objectKey === undefined) {
      return;
    } else if (objectKey === "page_url") {
      setModifiedData((prev) => ({
        ...prev,
        properties: {
          page_hits: prev?.properties?.page_hits,
          page_url: prev?.properties?.page_url,
          accumulative_time: prev.properties.accumulative_time ?? null,
          accumulative_time_unit: prev.properties.accumulative_time_unit,
          returns_after: prev.properties.returns_after ?? null,
          returns_after_unit: prev.properties.returns_after_unit,
          returns_within: prev.properties.returns_within ?? null,
          returns_within_unit: prev.properties.returns_within_unit,
        },
      }));
    } else {
      setModifiedData((prev) => ({
        ...prev,
        properties: {
          [objectKey]: [...new Set([...((prev.properties && prev.properties[objectKey]) || [])])],
        },
      }));
    }
  }, [objectKey]);

  useEffect(() => {
    if (types?.length && modifiedData?.type && modifiedData?.type !== "url.hit" && modifiedData?.type !== null) {
      findParentByValue(types, modifiedData?.type);
    } else if (modifiedData?.type === "url.hit") {
      setViewActionTypes(false);
      findParentByValue(types, "url.hit");
      setUrlTypes(true);
    } else {
      setSelectedType(null);
    }
  }, [types, modifiedData.type]);

  // function findParentByValue(options, searchValue) {
  //   for (const category of options) {
  //     if (category?.options && typeof category.options[Symbol.iterator] === "function") {
  //       const foundOption = category.options.find((option) => option?.value === searchValue);
  //       if (foundOption) {
  //         setSelectedType(foundOption);
  //         return;
  //       }
  //     }
  //   }
  // }

  function findParentByValue(options, searchValue) {
    console.log(options, searchValue);
    if (!Array.isArray(options)) {
      return;
    }
    for (const category of options) {
      if (Array.isArray(category?.options)) {
        const foundOption = category.options.find((option) => option?.value === searchValue);
        if (foundOption) {
          setSelectedType(foundOption);
          return;
        }
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (["name", "delta"].includes(name)) {
      if (value.length > 0) {
        delete errors[name];
      } else {
        errors[name] = true;
      }
      setErrors({ ...errors });
    }
    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = async (e) => {
    if (e && e.value !== modifiedData?.type) {
      setIsLoading(true);
      try {
        const response = await triggerPointActionEvents({ type: e.value });
        console.log("rrrr", response);
        if (response?.data?.modalData) {
          const key = Object.keys(response?.data?.modalData?.children)[0];
          setObjectKey(key);
          console.log(key);
          setPointActionTypes(response?.data?.modalData?.children[key]);
          setToltip(response?.data?.modalData?.children[key]?.attr?.tooltip);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
      setModifiedData((prev) => ({
        ...prev,
        type: e ? e?.value : null,
      }));
    }
    if (e?.value === "url.hit") {
      setViewActionTypes(false);
      setUrlTypes(true);
      setModifiedData((prev) => ({
        ...prev,
        properties: {
          page_hits: null,
          page_url: null,
          accumulative_time: null,
          accumulative_time_unit: "d",
          returns_after: null,
          returns_after_unit: "d",
          returns_within: null,
          returns_within_unit: "d",
        },
      }));
    } else if (e?.value !== "url.hit") {
      setViewActionTypes(true);
      setUrlTypes(false);
    }

    if (modifiedData?.type !== undefined) {
      delete errors["type"];
      setErrors({ ...errors });
    } else {
      errors["type"] = true;
      setErrors({ ...errors });
    }
  };

  const formatGroupLabel = (data) => (
    <div>
      <span
        style={{
          fontWeight: "bold",
          color: "#000",
        }}
      >
        {" "}
        {data?.label}
      </span>
    </div>
  );

  function transformChoices(choices) {
    if (selectedType?.value === "form.submit") {
      return [
        {
          label: t("caymland.core.theme.feature.form"),
          options: Object.keys(choices).flatMap((key) => {
            return choices[key].map((choice) => ({
              label: choice.label,
              value: choice.value,
            }));
          }),
        },
      ];
    } else {
      return Object.keys(choices).map((key) => {
        return {
          label: isNaN(+key) ? key : undefined,
          options: choices[key].map((choice) => ({
            label: choice.label,
            value: choice.value,
          })),
        };
      });
    }
  }

  function handleSelect(e, objectKey) {
    let shouldOpenNewTab = false;

    let filteredOptions = e
      ? e.filter((option) => {
          if (option.value === "new") {
            shouldOpenNewTab = true;
            return false;
          }
          return true;
        })
      : null;

    filteredOptions = filteredOptions ? filteredOptions.map((option) => option.value) : null;

    if (shouldOpenNewTab) {
      window.open("/emails/new", "_blank");
      return;
    }

    if (e) {
      const newSet = e.reduce((set, item) => {
        set.add(item.value);
        return set;
      }, new Set());
      const valuesArray = e.map((obj) => obj.value);
      setFilteredOptionsState(e);

      setModifiedData((prev) => ({
        ...prev,
        properties: {
          // ...prev.properties,
          [objectKey]: [
            ...valuesArray,
            // ...new Set([
            //   ...(prev.properties[objectKey] || []),
            //   ...filteredOptions,
            // ]),
          ],
        },
      }));
    }
  }

  const handleURLChange = async (e) => {
    setModifiedData((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        page_url: e.target.value,
      },
    }));
  };

  const handlePageHitsChange = async (e) => {
    setModifiedData((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        page_hits: e.target.value,
      },
    }));
  };

  const returnsAfterUnit = HDM.find((option) => option?.value === modifiedData?.properties?.returns_after_unit);

  const returnsWithinUnit = HDM.find((option) => option?.value === modifiedData?.properties?.returns_within_unit);

  const accumulativeTimeUnit = HDM.find((option) => option?.value === modifiedData?.properties?.accumulative_time_unit);

  function createHandleValue(property) {
    return (e) => {
      setModifiedData((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [property]: e.value,
        },
      }));
    };
  }

  function createInputChange(valueProperty, unitProperty) {
    return (e) => {
      let value = e?.target?.value;

      setModifiedData((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [valueProperty]: value,
        },
      }));
    };
  }

  const handleReturnsWithinValue = (value) => {
    createHandleValue("returns_within_unit")(value);
  };

  const handleReturnsAfterValue = (value) => {
    createHandleValue("returns_after_unit")(value);
  };

  const handleAccumulativeTimeValue = (value) => {
    createHandleValue("accumulative_time_unit")(value);
  };

  const inputChangeReturnsWithin = (value) => {
    createInputChange("returns_within", "returns_within_unit")(value);
    setModifiedData((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        returns_after: null,
      },
    }));
  };

  const inputChangeReturnsAfter = (value) => {
    createInputChange("returns_after", "returns_after_unit")(value);
    setModifiedData((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        returns_within: null,
      },
    }));
  };

  const inputChangeAccumulativeTime = createInputChange("accumulative_time", "accumulative_time_unit");

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#135f95",
      primary: "#135f95",
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#135f95" : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  return (
    <Fragment>
      <div className="flex flex-row w-full gap-10">
        <div className="flex flex-col gap-10 w-50">
          <Text
            name="name"
            value={modifiedData?.name}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.dashboard.widget.form.name")}
            errors={errors?.name}
            required={true}
          />
          <div className="w-full small-editor">
            <TextArea
              label={t("caymland.lead.field.companydescription")}
              name="Description"
              value={modifiedData?.description ?? ""}
              onChange={(data) => {
                setModifiedData((prev) => ({
                  ...prev,
                  description: data,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-10 w-50">
          <Text
            name="delta"
            type="number"
            tooltip={t("caymland.point.action.delta.help")}
            value={modifiedData?.delta}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.point.action.delta")}
            errors={errors?.delta}
            required={true}
          />
          <div style={{ zIndex: "5" }}>
            <ReusableSelect
              name="types"
              label={t("caymland.point.form.type")}
              required={true}
              loading={isLoading}
              errors={errors?.type}
              options={retrieveActionTypes}
              value={selectedType ? selectedType : null}
              onChange={(e) => handleSelectChange(e)}
            />
          </div>
          {viewActionTypes && (
            <div>
              <p className={`pb-0 mb-0 ${pointActionTypes?.required ? "required" : ""}`}>
                {isLoading || !limitSelected.length ? (
                  <>
                    <ClipLoader size={14} color="#686868" />~
                  </>
                ) : (
                  <>
                    <Label name={pointActionTypes?.label} label={pointActionTypes?.label || ""} tooltip={tootlip} />
                  </>
                )}
              </p>
              <Select
                id={pointActionTypes.id}
                name="lists"
                isSearchable
                isLoading={isLoading}
                classNamePrefix={`${errors?.lists && "error-select"}`}
                isMulti
                isClearable
                options={limitSelected}
                formatGroupLabel={formatGroupLabel}
                value={filteredOptionsState ? filteredOptionsState : null}
                placeholder={isLoading ? "Loading..." : "Select..."}
                isDisabled={isLoading ? true : false}
                onChange={(e) => handleSelect(e, objectKey)}
                theme={selectTheme}
                styles={selectStyles}
              />
            </div>
          )}

          {urlTypes && (
            <>
              <Text
                name="PageURL"
                tooltip={t("caymland.page.point.action.form.page.url.descr")}
                value={modifiedData?.properties?.page_url && modifiedData?.properties?.page_url}
                onChange={(e) => {
                  handleInputChange(e);
                  const newValue = e.target.value;
                  setModifiedData((prevState) => ({
                    ...prevState,
                    properties: {
                      ...prevState.properties,
                      page_url: newValue,
                    },
                  }));
                }}
                label={t("caymland.page.point.action.form.page.url")}
                placeholder="http://"
              />
              <Text
                name="Page Hits"
                tooltip={t("caymland.page.point.action.form.page.hits.descr")}
                value={modifiedData.properties.page_hits}
                onChange={(e) => {
                  handlePageHitsChange(e);
                }}
                label={t("caymland.page.graph.line.hits")}
                type="number"
              />
              <div className="flex gap-10" style={{ width: "100%" }}>
                <div style={{ width: "60%" }}>
                  <Text
                    name="ReturnsWithin"
                    tooltip={t("caymland.page.point.action.form.returns.within.descr")}
                    marginBottom
                    value={modifiedData?.properties?.returns_within ?? "0"}
                    onChange={inputChangeReturnsWithin}
                    label={t("caymland.page.point.action.form.returns.within")}
                  />
                </div>

                <div style={{ width: "40%", marginTop: "20px" }}>
                  <ReusableSelect
                    name="ReturnsWithin"
                    label={""}
                    margin
                    isClearable={false}
                    options={HDM}
                    value={returnsWithinUnit}
                    onChange={handleReturnsWithinValue}
                  />
                </div>
              </div>
              <div className="flex gap-10" style={{ width: "100%" }}>
                <div style={{ width: "60%" }}>
                  <Text
                    name="ReturnsAfter"
                    margin
                    tooltip={t("caymland.page.point.action.form.returns.after.descr")}
                    value={modifiedData?.properties?.returns_after ?? "0"}
                    onChange={inputChangeReturnsAfter}
                    label={t("caymland.page.stage.action.form.returns.after")}
                  />
                </div>
                <div style={{ width: "40%", marginTop: "20px" }}>
                  <ReusableSelect
                    name="ReturnsAfter"
                    margin
                    label={""}
                    isClearable={false}
                    options={HDM}
                    value={returnsAfterUnit}
                    onChange={handleReturnsAfterValue}
                  />
                </div>
              </div>
              <div className="flex gap-10" style={{ width: "100%" }}>
                <div style={{ width: "60%" }}>
                  <Text
                    name="TotalTimeSpent"
                    tooltip={t("caymland.page.point.action.form.accumulative.time.descr")}
                    value={modifiedData?.properties?.accumulative_time ?? "0"}
                    onChange={inputChangeAccumulativeTime}
                    label={t("caymland.page.point.action.form.accumulative.time")}
                  />
                </div>
                <div style={{ width: "40%", marginTop: "20px" }}>
                  <ReusableSelect
                    name="TotalTimeSpent"
                    isClearable={false}
                    label={""}
                    options={HDM}
                    value={accumulativeTimeUnit}
                    onChange={handleAccumulativeTimeValue}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Details;
