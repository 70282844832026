import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import {
  useAddFolderReportsMutation,
  useDeleteFolderReportsMutation,
  useDeleteReportMutation,
  useGetFolderReportsQuery,
  useGetReportsQuery,
  useGetSourcesQuery,
  useRemoveReportsToFoldersMutation,
  useUpdateFolderReportsMutation,
  useUpdateReportMutation,
  useUpdateReportsToFoldersMutation,
} from "../../../redux/api/reportsApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { t } from "i18next";
import { ReportSearchHelper } from "../../reusableComponents/HelperTexts/SearchHelper";

function Reports(props) {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const [activeRowId, setActiveRowId] = useState(null);
  const [editingFolder, setEditingFolder] = useState();
  const name = "Reports";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "reports",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "reports",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const { data: reportsData, isFetching: reportsIsFetching, refetch: refetchTableData } = useGetReportsQuery(query);
  const { data: folderReportsData, refetch } = useGetFolderReportsQuery();
  const [updateReport] = useUpdateReportMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [addFolder] = useAddFolderReportsMutation();
  const [updateFolder] = useUpdateFolderReportsMutation();
  const [deleteFolder] = useDeleteFolderReportsMutation();
  const [updateReportsToFolder] = useUpdateReportsToFoldersMutation();
  const [removeReportsFromFolder] = useRemoveReportsToFoldersMutation();
  const { data: sourcesData, isLoading: sourcesLoading } = useGetSourcesQuery();

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/reports/new"),
    },
  ];

  const findSelectedOption = (sources, value) => {
    if (!Array.isArray(sources)) {
      return null;
    }
    let selectedOption = null;
    sources?.some((group) => {
      selectedOption = group?.options?.find((option) => option?.value === value);
      return selectedOption !== undefined;
    });
    return selectedOption;
  };

  const columns = [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.user.account.permissions.editname"),
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="reports"
          permissionKey="report:reports"
          view={true}
        />
      ),
    },
    {
      accessorKey: "source",
      accessorFn: (row) => row.source,
      header: t("caymland.report.report.form.source"),
      enableColumnActions: false,
      Cell: ({ cell }) => sourcesData && findSelectedOption(sourcesData, cell?.row?.original?.source)?.label,
    },
  ];

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const body = [<CampaignForm control={control} />];

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { ...data, isPublished: !data.isPublished };
    await updateReport(updatedData);
    await refetchTableData();
    setLocalLoading(-1);
  };

  const actionButtons = ActionButtons({
    link: "reports",
    removeItemFromFolder: removeReportsFromFolder,
    deleteItem: deleteReport,
    refetch: refetchTableData,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    permissionKey: "report:reports",
    tableData: reportsData?.data || [],
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "reports",
    refetch: refetchTableData,
    permissionKey: "report:reports",
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.report.reports")} />
      <DragAndDropTable
        data={reportsData}
        folderData={folderReportsData}
        columns={columns}
        isFetching={reportsIsFetching || sourcesLoading}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateReportsToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        searchHelperBody={<ReportSearchHelper />}
      />
    </Fragment>
  );
}

export default Reports;
