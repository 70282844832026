import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PublishStatus from "../PublishStatusIcon/PublishStatus";
import { SiGoogletranslate, SiMinutemailer } from "react-icons/si";
import { FaCog } from "react-icons/fa";
import { TbBan } from "react-icons/tb";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { t } from "i18next";
import he from "he";

export const LinkComponent = ({
  data,
  loading,
  handleTogglePublished,
  link,
  view,
  modal,
  disableLink = false,
  IpShow,
  permissionKey = "none",
}) => {
  const [reasonMapping, setReasonMapping] = useState([]);

  useEffect(() => {
    // Initialize reasonMapping with translations inside useEffect
    setReasonMapping([
      { label: t("caymland.preferences.reason.single.opt.in"), value: 0, color: "#04B76B" },
      { label: t("caymland.preferences.reason.double.opt.in"), value: 10, color: "#04B76B" }, // Double Opt-In
      { label: t("caymland.preferences.reason.unsubscribed"), value: 1, color: "#f86b4f" },
      { label: t("caymland.preferences.reason.bounced"), value: 2, color: "#fdb933" },
      {
        label: t("caymland.preferences.reason.manual") + " " + t("caymland.preferences.reason.unsubscribed"),
        value: 3,
        color: "#f86b4f",
      },
      { label: t("caymland.preferences.reason.soft"), value: 4, color: "#fdb933" },
      { label: t("caymland.preferences.reason.blacklist"), value: 5, color: "black" },
    ]);
  }, [t]); // Depend on `t` to ensure it updates if translation function reloads

  // Helper function to find the corresponding reason in the mapping
  const getReasonDetails = (reasonValue) => {
    return reasonMapping.find((r) => r.value === reasonValue);
  };

  let MyName = data?.fields?.all?.firstname || "";
  let MyLastName = data?.fields?.all?.lastname || "";
  let MyIpAddress = data?.ipAddresses?.[0]?.ip || "";
  const LinkName = IpShow === "is:anonymous" ? MyIpAddress : `${MyName} ${MyLastName}`;

  const content = (
    <>
      {link === "contacts/fields"
        ? data?.label
        : link === "users"
        ? `${data.lastName}, ${data.firstName}`
        : link === "pages" || link === "assets"
        ? `${he.decode(data?.title)} (${data?.alias})`
        : link === "contacts"
        ? LinkName
        : link === "companies"
        ? data?.fields?.core?.companyname?.value
        : data?.name || data?.title || data?.tag || data.originalFile || data?.purpose}
    </>
  );

  const description = (
    <div className="text-muted">
      <p
        style={{
          color: "#989898",
          fontSize: "11px",
          maxWidth: "200px",
        }}
      >
        <span
          style={{ fontSize: 10 }}
          dangerouslySetInnerHTML={{ __html: data?.description || data?.content || data?.metaDescription }}
        ></span>
      </p>
    </div>
  );

  const renderDoNotContactOrOptInIcon = () => {
    const contactItems = data.doNotContact?.length
      ? data.doNotContact
      : data.optInContact?.length
      ? data.optInContact
      : null;

    if (!contactItems || !contactItems.length) return null;

    const reasonDetails = getReasonDetails(contactItems[0]?.reason);

    if (!reasonDetails) return null;

    return (
      <div style={{ marginLeft: "5px" }}>
        <Tooltip
          arrow
          followCursor
          placement="top"
          TransitionComponent={Zoom}
          title={reasonDetails.label}
          style={{ padding: "0" }}
        >
          <IconButton>
            <div
              className="grid place-center"
              style={{
                background: reasonDetails.color,
                borderRadius: "50%",
                padding: "4px",
                width: "100%",
              }}
            >
              {reasonDetails.value === 10 ? (
                <SiMinutemailer color="#fff" size={15} />
              ) : (
                <TbBan color="#fff" size={15} />
              )}
            </div>
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={`flex flex-col p-no-margin`}>
      <div className="flex items-center justify-start gap-10">
        {handleTogglePublished && (
          <PublishStatus
            data={data}
            handleTogglePublished={handleTogglePublished}
            index={1}
            permissionKey={permissionKey}
          />
        )}
        {!disableLink ? (
          <Link className="table-links" to={`/${link}/${view ? "view" : "edit"}/${data?.id}`}>
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>{content}</div>
            {description && description}
          </Link>
        ) : (
          <Link
            className="table-links"
            onClick={(e) => {
              if (modal && link !== "categories") {
                e.preventDefault();
                e.stopPropagation();
                modal({ isOpen: true, type: "edit", id: data.id });
              }
              if (link === "categories") {
                modal("Edit", data?.id);
              }
            }}
          >
            {content}
            {description && description}
          </Link>
        )}
        {link === "segments" && data.isGlobal === true ? <PublicIcon style={{ fontSize: "14px" }} /> : null}
        {data?.iconClass && (
          <div style={{ marginLeft: "5px" }}>
            <i className={data?.iconClass}></i>
          </div>
        )}
        {data?.isPreferenceCenter && (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.core.icon_tooltip.preference_center")}
            style={{ padding: "0" }}
          >
            <div className="d-flex">
              <FaCog size={14} />
            </div>
          </Tooltip>
        )}
        {data?.translationChildren?.length ? (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.core.icon_tooltip.translation")}
            style={{ padding: "0" }}
          >
            <div className="d-flex">
              <SiGoogletranslate size={14} />
            </div>
          </Tooltip>
        ) : null}
        {renderDoNotContactOrOptInIcon()}
      </div>
    </div>
  );
};
