import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import Button from "./Button";
import { useDeleteContactsMutation } from "../../../redux/api/contactsApi";
import { SlideAlert } from "../Alerts";
import Swal from "sweetalert2";
import { Tooltip } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export default function Delete(props) {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const deleteMessage = () => {
    setAlertMessage(`The contact has been deleted successfully!`);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const naviagte = useNavigate();

  const [deleteContact] = useDeleteContactsMutation();

  const handleDelete = async (data) => {
    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: props?.firstName })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    });
    if (result.isConfirmed) {
      deleteContact(props.id);

      naviagte("/contacts");
    }
  };

  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      <Button
        color="error"
        buttons={[
          {
            name: !props.noText && "Delete",
            title: !props.noText && t("caymland.core.form.delete"),
            icon: (
              <Tooltip title={t("caymland.campaign.lead.deletecontact")} arrow>
                {" "}
                <FaTrashAlt size={15} />
              </Tooltip>
            ),
            onClick: handleDelete,
          },
        ]}
      />
    </>
  );
}
