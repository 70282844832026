import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Text } from "../reusableComponents/Inputs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SlideAlert } from "../reusableComponents/Alerts";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");
  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;
  const [backgroundUrl, setBackgroundUrl] = useState("");

  const sendResetPasswordEmail = async () => {
    try {
      const endpoint = `${api}/passwordresetV2`;
      const response = await axios.post(endpoint, { identifier: username });
      if (response.status === 200) {
        setShow(true);
        setMessage(t("A new reset password link has been sent to you. Please check your email."));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 6000);
      }
    } catch (error) {
      console.error("Failed to send email", error);
    }
  };

  useEffect(() => {
    // Check if a background image already exists in localStorage
    const storedBackgroundUrl = localStorage.getItem("backgroundUrl");
    const backgroundFetchTime = localStorage.getItem("backgroundFetchTime");
    const currentTime = Date.now();

    // If the image exists and was fetched within the last hour, use it
    if (
      storedBackgroundUrl &&
      backgroundFetchTime &&
      currentTime - backgroundFetchTime < 60 * 60 * 1000 // 1 hour in milliseconds
    ) {
      setBackgroundUrl(storedBackgroundUrl);
    } else {
      // Otherwise, fetch a new image
      fetchBackgroundImage();
    }

    // Set an interval to refresh the background every hour
    const interval = setInterval(() => {
      fetchBackgroundImage();
    }, 60 * 60 * 1000); // 1 hour

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} />
      <div className="page-wrapper">
        <div
          className="auth-bg"
          style={{
            backgroundImage: `url(${
              backgroundUrl || "http://localhost:3000/static/media/auth-bg-1.8184142c45a47fd52a9c.jpg"
            })`,
          }}
        >
          <div className="authentication-box">
            <div className="tw-flex justify-center text-center">
              <img src="https://dev.m-4.ch/media/images/sidebar_logo.png" alt="" />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className=" text-center tw-mb-[10px]">
                  <h4>{t("Reset Password")}</h4>
                </div>
                <Text
                  name="_username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  label={t("Username or Email")}
                />
                <div className="forgotPassword">
                  <Link to="/login">{t("Back to Login")}</Link>
                </div>
                <div className="form-group form-row mt-3 mb-0 d-grid">
                  <button className="btn active-btn" onClick={sendResetPasswordEmail}>
                    {t("Reset Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
