import Default from "../dashboard/defaultCompo/default";
import Contacts from "../main-menu-sidebar/contacts";
import CustomFields from "../right-menu-components/customFields";
import AddCustomField from "../right-menu-components/customFields/AddCustomField";
import Categories from "../right-menu-components/categories";
import Companies from "../main-menu-sidebar/companies";
import ViewCompany from "../main-menu-sidebar/companies/ViewCompany";
import AddNew from "../main-menu-sidebar/contacts/addNew";
import Segments from "../main-menu-sidebar/segments";
import AddNewSegment from "../main-menu-sidebar/segments/addNew";
import ViewSegment from "../main-menu-sidebar/segments/ViewSegment";
import EditCloneSegment from "../main-menu-sidebar/segments/EditCloneSegment";
import Assets from "../main-menu-sidebar/Components/Assets/Assets";
import Documentation from "../common/header-component/documentation";
import AddEditAsset from "../main-menu-sidebar/Components/Assets/AddEditAsset";
import ViewAsset from "../main-menu-sidebar/Components/Assets/ViewAsset";
import Forms from "../main-menu-sidebar/Components/Forms/Forms";
import AddNewForm from "../main-menu-sidebar/Components/Forms/AddNewFom";
import ViewForm from "../main-menu-sidebar/Components/Forms/ViewForm";
import FileManagerFunction from "../main-menu-sidebar/Components/fileManager/FileManagerFunction";
import LandingPages from "../main-menu-sidebar/Components/landingPages/LandingPages";
import DynamicContent from "../main-menu-sidebar/Components/dynamicContent/DynamicContent";
import NewLandingPage from "../main-menu-sidebar/Components/landingPages/NewLandingPage";
import Campaigns from "../main-menu-sidebar/campaigns/Campaigns";
import MarketingMessages from "../main-menu-sidebar/channels/marketingMessages/MarketingMessages";
import Emails from "../main-menu-sidebar/channels/emails/Emails";
import FocusItems from "../main-menu-sidebar/channels/focusItems/FocusItems";
import SocialMonitoring from "../main-menu-sidebar/channels/socialMonitoring/SocialMonitoring";
import ManageActions from "../main-menu-sidebar/points/manageActions/ManageActions";
import ManageTriggers from "../main-menu-sidebar/points/manageTriggers/ManageTriggers";
import Stages from "../main-menu-sidebar/stages/Stages";
import Reports from "../main-menu-sidebar/reports/Reports";
import ViewReport from "../main-menu-sidebar/reports/ViewReport";
import AddStage from "../main-menu-sidebar/stages/AddStage";
import AddAction from "../main-menu-sidebar/points/manageActions/AddAction";
import AddTrigger from "../main-menu-sidebar/points/manageTriggers/AddTrigger";
import ViewEmail from "../main-menu-sidebar/channels/emails/ViewEmail";
import AddEmail from "../main-menu-sidebar/channels/emails/AddEmail";
import SQLconditions from "../main-menu-sidebar/sqlConditions/SQLconditions";
import AddSQLCondition from "../main-menu-sidebar/sqlConditions/AddSQLcondition";
import AddDynamicContent from "../main-menu-sidebar/Components/dynamicContent/AddDynamicContent";
import AddCampaign from "../main-menu-sidebar/campaigns/AddCampaign";
import AddCompany from "../main-menu-sidebar/companies/AddCompany";
import Users from "../right-menu-components/users/Users";
import WebHooks from "../right-menu-components/webHooks/WebHooks";
import TagsEditor from "../right-menu-components/tagsEditor/TagsEditor";
import AddUser from "../right-menu-components/users/AddUser";
import EditEmail from "../main-menu-sidebar/channels/emails/EditEmail";
import ViewDWC from "../main-menu-sidebar/Components/dynamicContent/ViewDWC";
import ViewCampaign from "../main-menu-sidebar/campaigns/ViewCampaign";
import ViewPage from "../main-menu-sidebar/Components/landingPages/ViewPage";
import FormResults from "../main-menu-sidebar/Components/Forms/Results";
import DataTableToHTMLTable from "../main-menu-sidebar/Components/Forms/Results/HTMLTable";
import AddFocusItems from "../main-menu-sidebar/channels/focusItems/AddFocusItems";
import ViewFocusItem from "../main-menu-sidebar/channels/focusItems/ViewFocusItem";
import AddorEditReport from "../main-menu-sidebar/reports/AddorEditReport";
import AdvancedReportView from "../main-menu-sidebar/channels/emails/viewEmail/AdvancedReportView";
import Main from "../reusableComponents/ImportExport/Main";
import History from "../reusableComponents/ImportExport/History";
import EmailPreview from "../main-menu-sidebar/contacts/viewContact/EmailPreview";
import View from "../reusableComponents/ImportExport/View";
import ExportView from "../reusableComponents/ImportExport/ExportRender";
import ViewHTMLReports from "../main-menu-sidebar/reports/viewReport/ViewHTMLReports";
import PreviewFrom from "../main-menu-sidebar/Components/Forms/ViewForm/PreviewFrom";
import AddEditHooks from "../right-menu-components/webHooks/AddEditHooks";
import ViewHooks from "../right-menu-components/webHooks/ViewHooks";
import EditProfile from "../AccountSettings/EditProfile";
import ViewContact from "../main-menu-sidebar/contacts/ViewContactNew/ViewContact";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import LoginLayout from "../common/Layout/LoginLayout";
import Login from "../auth/Login";
import MainLayout from "../common/Layout/MainLayout";
import { t } from "i18next";
import ResetPassword from "../auth/ResetPassword";
import SetNewPassword from "../auth/SetNewPassword";
import EmailTesting from "../main-menu-sidebar/channels/emails/viewEmail/EmailTestingUrl/EmailTesting";
import EmailPreviewHash from "../main-menu-sidebar/contacts/viewContact/EmailPreviewHash";
import Sms from "../main-menu-sidebar/channels/sms/Sms";
import AddEditSMS from "../main-menu-sidebar/channels/sms/AddEditSMS";
import ViewSMS from "../main-menu-sidebar/channels/sms/ViewSMS";
import SendSms from "../main-menu-sidebar/channels/sms/SendSms";
import SerialLetters from "../main-menu-sidebar/serialLetters/SerialLetters";
import SerialLetterForm from "../main-menu-sidebar/serialLetters/SerialLetterForm";
import MultipleExport from "../main-menu-sidebar/serialLetters/MultipleExport";
import MultipleExportFrom from "../main-menu-sidebar/serialLetters/MultipleExportForm";
import NotFoundPage from "../NotFound/NotFoundPage";
import Purposes from "../right-menu-components/Purposes/Purposes";
import Templates from "../main-menu-sidebar/templetes/Templates";
import AddEditTemplete from "../main-menu-sidebar/templetes/AddEditTemplete";
import Orders from "../main-menu-sidebar/orders/Orders";
import AddEditOrders from "../main-menu-sidebar/orders/AddEditOrders";
import Products from "../main-menu-sidebar/products/Products";
import Mailbox from "../main-menu-sidebar/mailbox/Mailbox";
import AddEditProducts from "../main-menu-sidebar/products/AddEditProducts";
import Classification from "../right-menu-components/mailboxAdmin/Classification/Classification";
import AddEditClassification from "../right-menu-components/mailboxAdmin/Classification/AddEditClassification";
import Template from "../right-menu-components/mailboxAdmin/Template/Template";
import AddEditTemplate from "../right-menu-components/mailboxAdmin/Template/AddEditTemplate";
import Rule from "../main-menu-sidebar/rules/Rules";
import AddEditRules from "../main-menu-sidebar/rules/AddEditRules";

export const customRoutes = [
  {
    layout: MainLayout,
    routes: [
      {
        title: t("caymland.dashboard.header.index"),
        icon: DashboardOutlinedIcon,
        path: "dashboard",
        element: <Default />,
        children: [],
        permissionKey: "api:access",
      },
      {
        title: t("caymland.core.documentation"),
        path: "documentation",
        element: <Documentation />,
        children: [],
      },
      {
        title: t("caymland.lead.leads"),
        icon: PeopleOutlinedIcon,
        path: "contacts",
        permissionKey: "lead:leads",
        element: <Contacts />,
        children: [
          {
            path: "contacts/new",
            element: <AddNew />,
            permissionValue: "create",
          },
          {
            path: "contacts/edit/:id",
            element: <AddNew />,
            permissionValue: "editown",
          },
          {
            path: "contacts/clone/:id",
            element: <AddNew />,
            permissionValue: "create",
          },
          {
            path: "contacts/view/:id",
            element: <ViewContact />,
            permissionValue: "viewown",
          },
          {
            path: "contacts/import/new",
            element: <Main />,
            permissionKey: "lead:imports",
            permissionValue: "create",
          },
          {
            path: "contacts/import",
            element: <History />,
            permissionKey: "lead:imports",
            permissionValue: "view",
          },
          {
            path: "contacts/import/view/:id",
            element: <View />,
            permissionKey: "lead:imports",
            permissionValue: "view",
          },
          {
            path: "contacts/export/custom",
            element: <ExportView />,
            permissionKey: "lead:exports",
            permissionValue: "view",
          },
          {
            path: "contacts/fields",
            element: <CustomFields />,
            permissionKey: "lead:fields",
          },
          {
            path: "contacts/fields/edit/:id",
            element: <AddCustomField />,
            permissionKey: "lead:fields",
            permissionValue: "full",
          },
          {
            path: "contacts/fields/clone",
            element: <AddCustomField />,
            permissionKey: "lead:fields",
            permissionValue: "full",
          },
          {
            path: "contacts/fields/new",
            element: <AddCustomField />,
            permissionKey: "lead:fields",
            permissionValue: "full",
          },
        ],
      },
      {
        title: t("caymland.lead.tags"),
        path: "profile",
        element: <></>,
        children: [
          {
            path: "edit-profile",
            element: <EditProfile />,
          },
        ],
      },
      {
        path: "products",
        element: <Products />,
        permissionKey: "brochures:products",
        pluginName: "Brochures",
        children: [
          {
            path: "products/new",
            element: <AddEditProducts />,
            permissionValue: "create",
            pluginName: "Brochures",
          },
          {
            path: "products/clone",
            element: <AddEditProducts />,
            permissionValue: "create",
            pluginName: "Brochures",
          },
          {
            path: "products/edit/:id",
            element: <AddEditProducts />,
            permissionValue: "editown",
            pluginName: "Brochures",
          },
        ],
      },
      {
        path: "templates",
        element: <Templates />,
        permissionKey: "brochures:templates",
        pluginName: "Brochures",
        children: [
          {
            path: "templates/new",
            element: <AddEditTemplete />,
            pluginName: "Brochures",
            permissionValue: "create",
          },
          {
            path: "templates/clone",
            element: <AddEditTemplete />,
            pluginName: "Brochures",
            permissionValue: "create",
          },
          {
            path: "templates/edit/:id",
            element: <AddEditTemplete />,
            pluginName: "Brochures",
            permissionValue: "editown",
          },
        ],
      },
      {
        path: "orders",
        element: <Orders />,
        permissionKey: "brochures:orders",
        pluginName: "Brochures",
        children: [
          {
            path: "orders/new",
            element: <AddEditOrders />,
            pluginName: "Brochures",
            permissionValue: "create",
          },
          {
            path: "orders/clone",
            element: <AddEditOrders />,
            pluginName: "Brochures",
            permissionValue: "create",
          },
          {
            path: "orders/edit/:id",
            element: <AddEditOrders />,
            pluginName: "Brochures",
            permissionValue: "editown",
          },
        ],
      },
      {
        path: "mailbox",
        element: <Mailbox />,
        permissionKey: "mailbox:items",
        pluginName: "Mailbox",
      },
      {
        title: t("caymland.company"),
        icon: BusinessOutlinedIcon,
        path: "companies",
        permissionKey: "lead:leads",
        element: <Companies />,
        children: [
          {
            path: "companies/new",
            element: <AddCompany />,
            permissionValue: "create",
          },
          {
            path: "companies/edit/:id",
            element: <AddCompany />,
            permissionValue: "editown",
          },
          {
            path: "companies/clone",
            element: <AddCompany />,
            permissionValue: "create",
          },
          {
            path: "companies/view/:id",
            element: <ViewCompany />,
            permissionValue: "viewown",
          },
          {
            path: "companies/import/new",
            element: <Main />,
            permissionKey: "lead:imports",
            permissionValue: "create",
          },
          {
            path: "companies/import/history",
            element: <History />,
            permissionKey: "lead:imports",
            permissionValue: "view",
          },
          {
            path: "companies/import/history/view/:id",
            element: <View />,
            permissionKey: "lead:imports",
            permissionValue: "view",
          },
        ],
      },
      {
        title: "Categories",
        icon: CategoryOutlinedIcon,
        path: "categories",
        element: <Categories />,
        children: [],
        permissionKey: "category:categories",
      },
      {
        title: t("caymland.category.header.index"),
        icon: FilterAltOutlinedIcon,
        path: "segments",
        element: <Segments />,
        permissionKey: "lead:lists",
        children: [
          {
            path: "segments/new",
            element: <AddNewSegment />,
            permissionValue: "create",
          },
          {
            path: "segments/view/:id",
            element: <ViewSegment />,
            permissionValue: "viewother",
          },
          {
            path: "segments/edit/:id",
            element: <EditCloneSegment />,
            permissionValue: "editother",
          },
          {
            path: "segments/clone",
            element: <EditCloneSegment />,
            permissionValue: "create",
          },
        ],
      },
      {
        path: "serial-letters",
        element: <SerialLetters />,
        pluginName: "BadgeGenerator",
        children: [
          {
            path: "serial-letters/new",
            element: <SerialLetterForm />,
            pluginName: "BadgeGenerator",
            permissionValue: "create",
          },
          {
            path: "serial-letters/edit/:id",
            element: <SerialLetterForm />,
            pluginName: "BadgeGenerator",
            permissionValue: "editown",
          },
          {
            path: "serial-letters/clone",
            element: <SerialLetterForm />,
            pluginName: "BadgeGenerator",
            permissionValue: "create",
          },
        ],
        permissionKey: "mailbox:template",
      },
      {
        path: "multiple-exports",
        element: <MultipleExport />,
        pluginName: "BadgeGenerator",
        children: [
          {
            path: "multiple-exports/new",
            element: <MultipleExportFrom />,
            pluginName: "BadgeGenerator",
            permissionValue: "create",
          },
          {
            path: "multiple-exports/edit/:id",
            element: <MultipleExportFrom />,
            pluginName: "BadgeGenerator",
            permissionValue: "edit",
          },
          {
            path: "multiple-exports/clone",
            element: <MultipleExportFrom />,
            pluginName: "BadgeGenerator",
            permissionValue: "create",
          },
        ],
        permissionKey: "lead:exports",
      },
      {
        path: "assets",
        element: <Assets />,
        children: [
          {
            path: "assets/new",
            element: <AddEditAsset />,
            permissionValue: "create",
          },
          {
            path: "assets/view/:id",
            element: <ViewAsset />,
            permissionValue: "viewown",
          },
          {
            path: "assets/edit/:id",
            element: <AddEditAsset />,
            permissionValue: "editown",
          },
          {
            path: "assets/clone",
            element: <AddEditAsset />,
            permissionValue: "create",
          },
        ],
        permissionKey: "asset:assets",
      },
      {
        path: "sms",
        element: <Sms />,
        pluginName: "SMS",
        children: [
          {
            path: "sms/new",
            element: <AddEditSMS />,
            permissionValue: "create",
            pluginName: "SMS",
          },
          {
            path: "sms/edit/:id",
            element: <AddEditSMS />,
            permissionValue: "editown",
            pluginName: "SMS",
          },
          {
            path: "sms/view/:id",
            element: <ViewSMS />,
            permissionValue: "viewown",
            pluginName: "SMS",
          },
          {
            path: "sms/clone",
            element: <AddEditSMS />,
            permissionValue: "create",
            pluginName: "SMS",
          },
          {
            path: "sms/send/:id",
            element: <SendSms />,
            pluginName: "SMS",
          },
        ],
        permissionKey: "sms:smses",
      },
      {
        path: "forms",
        element: <Forms />,
        children: [
          {
            path: "forms/new",
            element: <AddNewForm />,
            permissionValue: "create",
          },
          {
            path: "forms/view/:id",
            element: <ViewForm />,
            permissionValue: "viewown",
          },
          {
            path: "forms/edit/:id",
            element: <AddNewForm />,
            permissionValue: "editown",
          },
          {
            path: "forms/clone",
            element: <AddNewForm />,
            permissionValue: "create",
          },
          {
            path: "forms/results/:id",
            element: <FormResults />,
            permissionValue: "viewown",
          },
          {
            path: "forms/results/:id/export/html",
            element: <DataTableToHTMLTable />,
            permissionKey: "lead:exports",
            permissionValue: "create",
          },
        ],
        permissionKey: "form:forms",
      },
      {
        path: "file_manager",
        element: <FileManagerFunction />,
        permissionKey: "fileManager:items",
        children: [],
      },
      {
        path: "pages",
        element: <LandingPages />,
        children: [
          {
            path: "pages/new",
            element: <NewLandingPage />,
            permissionValue: "create",
          },
          {
            path: "pages/edit/:id",
            element: <NewLandingPage />,
            permissionValue: "editown",
          },
          {
            path: "pages/view/:id",
            element: <ViewPage />,
            permissionValue: "viewown",
          },
          {
            path: "pages/clone",
            element: <NewLandingPage />,
            permissionValue: "create",
          },
        ],
        permissionKey: "page:pages",
      },
      {
        path: "messages",
        element: <MarketingMessages />,
        permissionKey: "channel:messages",
        children: [],
      },
      {
        path: "monitoring",
        element: <SocialMonitoring />,
        permissionKey: "caymlandSocial:monitoring",
        children: [],
      },
      {
        path: "focus",
        element: <FocusItems />,
        children: [
          {
            path: "focus/new",
            element: <AddFocusItems />,
            permissionValue: "create",
          },
          {
            path: "focus/edit/:id",
            element: <AddFocusItems />,
            permissionValue: "editown",
          },
          {
            path: "focus/view/:id",
            element: <ViewFocusItem />,
            permissionValue: "viewown",
          },
          {
            path: "focus/clone",
            element: <AddFocusItems />,
            permissionValue: "create",
          },
        ],
        permissionKey: "focus:items",
      },
      {
        path: "stages",
        element: <Stages />,
        children: [
          {
            path: "stages/new",
            element: <AddStage />,
            permissionValue: "create",
          },
          {
            path: "stages/edit/:id",
            element: <AddStage />,
            permissionValue: "edit",
          },
          {
            path: "stages/clone",
            element: <AddStage />,
            permissionValue: "create",
          },
        ],
        permissionKey: "stage:stages",
      },
      {
        path: "reports",
        element: <Reports />,
        children: [
          {
            path: "reports/new",
            element: <AddorEditReport />,
            permissionValue: "create",
          },
          {
            path: "reports/clone",
            element: <AddorEditReport />,
            permissionValue: "create",
          },
          {
            path: "reports/edit/:id",
            element: <AddorEditReport />,
            permissionValue: "editown",
          },
          {
            path: "reports/view/:id",
            element: <ViewReport />,
            permissionValue: "viewown",
          },
          {
            path: "reports/view/:id/html/export",
            element: <ViewHTMLReports />,
          },
        ],
        permissionKey: "report:reports",
      },
      {
        path: "points",
        element: <ManageActions />,
        children: [
          {
            path: "points/new",
            element: <AddAction />,
            permissionValue: "create",
          },
          {
            path: "points/edit/:id",
            element: <AddAction />,
            permissionValue: "edit",
          },
          {
            path: "points/clone",
            element: <AddAction />,
            permissionValue: "create",
          },
        ],
        permissionKey: "point:points",
      },
      {
        path: "triggers",
        element: <ManageTriggers />,
        children: [
          {
            path: "triggers/new",
            element: <AddTrigger />,
            permissionValue: "create",
          },
          {
            path: "triggers/edit/:id",
            element: <AddTrigger />,
            permissionValue: "edit",
          },
          {
            path: "triggers/clone",
            element: <AddTrigger />,
            permissionValue: "create",
          },
        ],
        permissionKey: "point:triggers",
      },
      {
        path: "emails",
        element: <Emails />,
        children: [
          {
            path: "emails/new",
            element: <AddEmail />,
            permissionValue: "create",
          },
          {
            path: "emails/edit/:id",
            element: <EditEmail />,
            permissionValue: "editown",
          },
          {
            path: "emails/clone",
            element: <EditEmail />,
            permissionValue: "create",
          },
          {
            path: "emails/view/:id",
            element: <ViewEmail />,
            permissionValue: "viewown",
          },
          {
            path: "emails/advanced-report/:id",
            element: <AdvancedReportView />,
            permissionValue: "viewown",
          },
          {
            path: "email/testing/:id",
            element: <EmailTesting />,
            permissionValue: "viewown",
          },
        ],
        permissionKey: "email:emails",
      },
      {
        path: "sqlConditions",
        element: <SQLconditions />,
        children: [
          {
            path: "sqlConditions/new",
            element: <AddSQLCondition />,
            permissionValue: "create",
          },
        ],
      },
      {
        path: "dwc",
        element: <DynamicContent />,
        children: [
          {
            path: "dwc/new",
            element: <AddDynamicContent />,
            permissionValue: "create",
          },
          {
            path: "dwc/edit/:id",
            element: <AddDynamicContent />,
            permissionValue: "editown",
          },
          {
            path: "dwc/clone",
            element: <AddDynamicContent />,
            permissionValue: "create",
          },
          {
            path: "dwc/view/:id",
            element: <ViewDWC />,
            permissionValue: "viewown",
          },
        ],
        permissionKey: "dynamiccontent:dynamiccontents",
      },
      {
        path: "campaigns",
        element: <Campaigns />,
        children: [
          {
            path: "campaigns/new",
            element: <AddCampaign />,
            permissionValue: "create",
          },
          {
            path: "campaigns/edit/:id",
            element: <AddCampaign />,
            permissionValue: "edit",
          },
          {
            path: "campaigns/clone",
            element: <AddCampaign />,
            permissionValue: "create",
          },
          {
            path: "campaigns/view/:id",
            element: <ViewCampaign />,
            permissionValue: "view",
          },
        ],
        permissionKey: "campaign:campaigns",
      },
      {
        path: "purposes",
        element: <Purposes />,
        children: [],
        permissionKey: "lead:leads",
      },
      {
        path: "users",
        element: <Users />,
        children: [
          {
            path: "users/new",
            element: <AddUser />,
            permissionValue: "create",
          },
          {
            path: "users/edit/:id",
            element: <AddUser />,
            permissionValue: "edit",
          },
        ],
        permissionKey: "user:users",
      },
      {
        path: "webhooks",
        element: <WebHooks />,
        children: [
          {
            path: "webhooks/new",
            element: <AddEditHooks />,
            permissionValue: "create",
          },
          {
            path: "webhooks/edit/:id",
            element: <AddEditHooks />,
            permissionValue: "editown",
          },
          {
            path: "webhooks/clone",
            element: <AddEditHooks />,
            permissionValue: "create",
          },
          {
            path: "webhooks/view/:id",
            element: <ViewHooks />,
            permissionValue: "viewown",
          },
        ],
        permissionKey: "webhook:webhooks",
      },

      {
        path: "mailbox-admin/classification",
        pluginName: "Mailbox",
        element: <Classification />,
        children: [
          {
            path: "mailbox-admin/classification/new",
            element: <AddEditClassification />,
          },
          {
            path: "mailbox-admin/classification/edit/:id",
            element: <AddEditClassification />,
          },
          {
            path: "mailbox-admin/classification/clone",
            element: <AddEditClassification />,
          },
        ],
      },
      {
        path: "mailbox-admin/rules",
        element: <Rule />,
        children: [
          {
            path: "mailbox-admin/rules/new",
            element: <AddEditRules />,
          },
          {
            path: "mailbox-admin/rules/edit/:id",
            element: <AddEditRules />,
          },
          {
            path: "mailbox-admin/rules/clone",
            element: <AddEditRules />,
          },
        ],
      },
      {
        path: "mailbox-admin/template",
        element: <Template />,
        pluginName: "Mailbox",
        children: [
          {
            path: "mailbox-admin/template/new",
            element: <AddEditTemplate />,
          },
          {
            path: "mailbox-admin/template/edit/:id",
            element: <AddEditTemplate />,
          },
          {
            path: "mailbox-admin/template/clone",
            element: <AddEditTemplate />,
          },
        ],
      },
      {
        path: "tags",
        element: <TagsEditor />,
        children: [],
        permissionKey: "tageditor:items",
      },
      {
        title: "404 Not Found",
        path: "*",
        element: <NotFoundPage />,
        children: [],
      },
    ],
  },
  {
    layout: LoginLayout,
    routes: [
      {
        name: "login",
        title: t("caymland.core.login.page"),
        element: <Login />,
        path: "login",
        isPublic: true,
      },
      {
        name: "reset",
        title: "reset",
        path: "reset",
        element: <ResetPassword />,
        isPublic: true,
      },
      {
        name: "set",
        title: "set",
        path: "passwordresetconfirmV2",
        element: <SetNewPassword />,
        isPublic: true,
      },
      {
        path: "email/preview/:id",
        element: <EmailPreview />,
      },
      {
        path: "form/preview/:id",
        element: <PreviewFrom />,
      },
      {
        path: "email/view/:id",
        element: <EmailPreviewHash />,
      },
    ],
  },
];
