import React, { useEffect } from "react";
import { FiClock } from "react-icons/fi";
import ClipLoader from "react-spinners/ClipLoader";
import { Fade, IconButton, Tooltip, Zoom } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import { useSelector } from "react-redux";

const PublishStatus = (props) => {
  const { data, loading, handleTogglePublished, index, permissionKey } = props;

  const isPending = dayjs().isBefore(dayjs(data.publishUp));
  const isExpired = dayjs().isAfter(dayjs(data.publishDown));

  const { rawPermissions: permissions = {}, isAdmin } = useSelector((state) => state?.auth?.user?.role || {});
  const user = useSelector((state) => state.auth.user);

  // Ensure permissions is an object and permissionKey is valid
  const hasPermission = (requiredPermissions) => {
    if (isAdmin || !permissionKey) {
      // Show all buttons if user is admin or permissionKey is undefined/null
      return true;
    }

    // Normalize requiredPermissions to an array
    const permissionsToCheck = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions];
    const userPermissions = permissions[permissionKey] || [];

    // Check if any of the requiredPermissions exist in userPermissions
    const hasAnyPermission = permissionsToCheck.some((perm) => userPermissions.includes(perm));

    return hasAnyPermission;
  };

  // Check if user can interact with the checkbox (based on permissions)
  let canToggle = hasPermission(["isAdmin", "full", "publish", "publishown", "publishother"]);

  // Additional checks for publishown and publishother
  if (canToggle) {
    if (permissions && permissions[permissionKey]) {
      if (permissions[permissionKey]?.includes("publishown") && data.createdBy !== user.id) {
        canToggle = false; // Deny if it's "publishown" and user is not the creator
      }
      if (permissions[permissionKey]?.includes("publishother") && data.createdBy === user.id) {
        canToggle = false; // Deny if it's "publishother" and user is the creator
      }
    }
  }

  return (
    <>
      <Tooltip
        arrow
        followCursor
        placement="top"
        TransitionComponent={Zoom}
        title={
          isExpired
            ? t("caymland.core.form.expired")
            : isPending
            ? t("caymland.message.queue.status.pending")
            : `${t("caymland.core.permissions.publish")} ${t("caymland.core.update.heading.status")}`
        }
        style={{ minWidth: "50px", alignSelf: "flex-start" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton>
          <div className="checkbox-wrapper-22">
            {isPending ? (
              <FiClock color="#fdb933" size={22} style={{ marginBottom: "6px" }} />
            ) : isExpired ? (
              <FiClock color="#f86b4f" size={22} style={{ marginBottom: "6px" }} />
            ) : loading === data.id ? (
              <ClipLoader color="#333" size={12} />
            ) : (
              <label
                className={`switch ${!canToggle ? "disabled" : ""}`}
                style={{ pointerEvents: canToggle ? "auto" : "none" }}
              >
                <input
                  type="checkbox"
                  checked={data.isPublished === true}
                  onChange={() => handleTogglePublished(data)}
                />
                <div className="slider round"></div>
              </label>
            )}
          </div>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PublishStatus;
