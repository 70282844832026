import React, { useEffect, useRef, useState } from "react";
import FocusEditor from "react-email-editor";
import { useGetBuilderConfigQuery } from "../../../../../redux/api/usersApi";
import { useSelector } from "react-redux";

const Builder = ({
  isOpen,
  loading,
  setModifiedData,
  modifiedData,
  setIsOpen,
  title,
  apply,
  setApplyAndCloseTemplateRef,
}) => {
  const focusEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const { data } = useGetBuilderConfigQuery("popup");
  const [initialLoad, setInitialLoad] = useState(true);
  const [editorReady, setEditorReady] = useState(false); // Track editor's readiness

  const applyAndCloseTemplate = (params) => {
    focusEditorRef.current.editor.exportHtml((data) => {
      const { design, html, chunks } = data;

      // Extract CSS from chunks.css and convert it to a string
      let css = typeof chunks.css === "string" ? chunks.css : JSON.stringify(chunks.css);

      // Replace the existing <style> tags with the new CSS
      var regex = /<style\b[^>]*>(.*?)<\/style>/gs;
      const updatedHtml = html.replace(regex, `<style type='text/css'>${css}</style>`);

      // Encode the modified HTML and design JSON
      const encodedHtml = Buffer.from(updatedHtml).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      // Update the state with the modified data
      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Directly update the editor with the latest design
      focusEditorRef.current.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setApplyBuilder("savebuilder");
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    // Pass the applyAndCloseTemplate function to the parent component
    if (setApplyAndCloseTemplateRef) {
      setApplyAndCloseTemplateRef(() => applyAndCloseTemplate);
    }
  }, [setApplyAndCloseTemplateRef]); // Only run when setApplyAndCloseTemplateRef changes

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [shouldApply]);

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;
  const token = useSelector((state) => state.auth.token);

  const onReady = () => {
    if (data && modifiedData?.json && initialLoad) {
      try {
        focusEditorRef?.current?.editor.loadDesign(JSON.parse(modifiedData.json));
        setEditorLoaded(true);
      } catch (error) {
        console.error("Error parsing JSON: ", error);
      }
    } else {
      console.error("JSON or data is empty");
    }
    const editorInstance = focusEditorRef?.current?.editor;

    if (editorInstance) {
      console.log("Editor is ready");

      // Register the image upload callback (only if not registered already)
      if (!editorInstance.hasRegisteredImageCallback) {
        editorInstance.hasRegisteredImageCallback = true;
        editorInstance.registerCallback("image", (file, done) => {
          console.log("Image upload callback triggered...");
          const data = new FormData();
          data.append("file", file.attachments[0]);

          fetch(`${api}/api/unlayer/image/upload`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `JWT ${token}`, // Add JWT token here
            },
            body: data,
          })
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                return response.json();
              } else {
                const error = new Error(`Failed to upload: ${response.statusText}`);
                error.response = response;
                throw error;
              }
            })
            .then((responseData) => {
              done({
                progress: 100,
                url: responseData?.fileUrl, // Adjust based on your API response
              });
            })
            .catch((error) => {
              console.error("Image upload failed:", error);
              done({
                progress: 0,
                url: null,
                error: "Upload failed. Please try again.",
              });
            });
        });
      }
    }
  };

  // Use a useEffect to wait until the data and modifiedData are both ready
  useEffect(() => {
    if (data && modifiedData?.json && !editorReady) {
      onReady(); // Call onReady only when everything is available
    }
  }, [data, modifiedData?.json]);

  useEffect(() => {
    setEditorReady(false);
  }, [modifiedData.customJson, data]);

  return <>{data && <FocusEditor ref={focusEditorRef} onReady={onReady} minHeight="100%" options={data} />}</>;
};

export default Builder;
