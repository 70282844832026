import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useGetContactFieldsQuery } from "../../../../redux/api/fieldsApi";
import { FieldsRenderSection } from "../FieldsRenderer";
import { useForm } from "react-hook-form";
import { useAddContactMutation, useAddCompanyToContactMutation } from "../../../../redux/api/contactsApi";
import StaticSelectsInForns from "../StaticSelectsInForns";
import { useNavigate } from "react-router-dom";
import { Success } from "../../../reusableComponents/Alerts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const QuickAddModal = ({ show, setShow, setShowAlert, setAlertMessage, id }) => {
  const { t } = useTranslation();
  const [contactFields, setContactFields] = useState();
  const [validationSchema, setValidationSchema] = useState(yup.object().shape({}));
  const navigate = useNavigate();
  const { data } = useGetContactFieldsQuery(undefined, { skip: !show });
  const [addContact] = useAddContactMutation();

  const closeModal = () => {
    setShow(false);
    reset();
  };
  useEffect(() => {
    if (data) {
      setContactFields(data.data.filter((field) => (field.isPublished && field.isShortVisible) || field.isRequired));
    }
  }, [data]);

  useEffect(() => {
    if (contactFields) {
      const schema = generateYupSchema(contactFields.filter((item) => item.isRequired));
      setValidationSchema(schema);
    }
  }, [contactFields]);

  const generateYupSchema = (fields) => {
    const schemaFields = fields.reduce((acc, field) => {
      let validator;
      switch (field.type) {
        case "email":
          validator = yup.string().email(t("caymland.core.valid.email")).required(t("caymland.core.fieldrequired"));
          break;
        default:
          validator = yup.string().required(t("caymland.core.fieldrequired"));
      }
      acc[field.alias] = validator;
      return acc;
    }, {});

    return yup.object().shape(schemaFields);
  };

  const [addContactToCompany] = useAddCompanyToContactMutation();
  const {
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      console.error(errors);
      if (errors) {
        setShowAlert(true);
        setAlertMessage(t("caymland.core.error.not.valid"));
        setTimeout(() => {
          setAlertMessage("");
          setShowAlert(false);
        }, 3000);
      }
    }
  }, [errors]);

  const handleContact = async (data) => {
    const response = await addContact(data);
    if (!response.error) {
      closeModal();
      addContactToCompany({ contactId: response?.data?.contact.id, companyId: id });
      navigate(`/contacts/view/${response?.data?.contact.id}`);
      setShowAlert(true);
      Success("Contact has been added or edited successfully!");
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3000);
    } else {
      setShowAlert(true);
      setAlertMessage(response.error.data.errors[0].message);
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3000);
    }
  };

  const buttons = [
    {
      name: "cancel",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleContact),
    },
  ];
  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{`${t("caymland.lead.lead.menu.quickadd")} ${t("caymland.lead.contact")}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "75vh", overflowY: "auto", gap: "10px", display: "flex", flexDirection: "column" }}
      >
        <FieldsRenderSection filteredFields={contactFields} control={control} noBorder errors={errors} />
        {contactFields && <StaticSelectsInForns control={control} watch={watch} setValue={setValue} noBorder />}
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default QuickAddModal;
