import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { t } from "i18next";
import {
  useAddFolderProductsMutation,
  useDeleteFolderProductsMutation,
  useDeleteProductMutation,
  useGetFolderProductsQuery,
  useGetProductsQuery,
  useRemoveProductsToFoldersMutation,
  useUpdateFolderProductsMutation,
  useUpdateProductMutation,
  useUpdateProductsToFoldersMutation,
  useReorderProductFieldsMutation,
} from "../../../redux/api/productsApi";
import { localeData } from "../../reusableComponents/data";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";

function Products(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const [localLoading, setLocalLoading] = useState(-1);
  const [order, setOrder] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const name = "Products";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem("orderByProducts") ? sessionStorage.getItem("orderByProducts") : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  const { data: productsData, isFetching: isFetching, refetch: refetchTableData } = useGetProductsQuery(query);
  const { data: folderProductsData, refetch } = useGetFolderProductsQuery();

  const [reorderProductFields] = useReorderProductFieldsMutation();
  const [addFolder] = useAddFolderProductsMutation();
  const [updateFolder] = useUpdateFolderProductsMutation();
  const [deleteFolder] = useDeleteFolderProductsMutation();

  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();

  const [updateProductsToFolder] = useUpdateProductsToFoldersMutation();
  const [removeProductsFromFolder] = useRemoveProductsToFoldersMutation();

  useEffect(() => {
    if (productsData?.data) {
      const newOrder = Array.isArray(productsData?.data) ? productsData?.data.map((item) => item.id) : [];
      setDataTable(productsData?.data);
      setOrder(newOrder);
    }
  }, [productsData]);

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "brochures.products",
    });
  }, [editingFolder]);

  const handleReorder = async (newOrder) => {
    let newFields = newOrder.map((id) => dataTable.find((item) => item.id === id));

    setDataTable({
      ...newFields,
    });

    const payload = {
      brochure_product: newOrder,
    };

    setDataTable(newFields);

    try {
      await reorderProductFields({ fieldOrder: payload }).unwrap();
    } catch (error) {
      console.error("Error reordering product fields:", error);
    }
  };

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "brochures.products",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const handleTogglePublished = async (data) => {
    const updatedData = { isPublished: !data.isPublished };
    await updateProduct({ id: data.id, ...updatedData });
    await refetchTableData();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: "Name",
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="products"
          permissionKey="brochures:products"
          view={false}
        />
      ),
      size: 100,
      enableColumnFilters: false,
    },
    {
      accessorKey: "language",
      accessorFn: (row) => row.language,
      header: t("caymland.mailbox.message.language"),
      size: 150,
      Cell: ({ cell }) => {
        const languageOption = localeData.find((option) => option.value === cell?.row?.original?.language);
        return <div>{languageOption ? t(languageOption.label) : cell?.row?.original?.language}</div>;
      },
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const actionButtons = ActionButtons({
    link: "products",
    deleteItem: deleteProduct,
    refetch: refetchTableData,
    removeItemFromFolder: removeProductsFromFolder,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    permissonKey: "brochures:products",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "brochures/products",
    refetch: refetchTableData,
    permissonKey: "brochures:products",
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "addnew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={17} />,
            onClick: () => navigate("/products/new"),
          },
        ]}
        title={t("caymland.brochures.menu.brochures")}
        showAlert={showAlert || success || fail}
        // alertMessage={showMessage}
      />
      <DragAndDropTable
        key={productsData?.data}
        filteredData={productsData?.data}
        folderData={folderProductsData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={<CampaignForm control={control} />}
        handleSubmit={handleSubmit}
        reset={reset}
        enableSorting={false}
        handleReorder={handleReorder}
        sort={false}
        rowDragging={true}
        order={order}
        setOrder={setOrder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateProductsToFolder}
        reorderBetween={true}
        linkNavigate="edit"
        toolbarActions={{
          select: true,
        }}
      />
    </Fragment>
  );
}

export default Products;
