import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const contactsApi = createApi({
  reducerPath: "contactsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Contacts", "Batch"],
  endpoints: (build) => ({
    getContacts: build.query({
      query: (q) =>
        `/api/contacts?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Contacts"],
    }),
    getContactsCategories: build.query({
      query: (id) => `/api/contacts/${id}/categories`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Contacts"],
    }),
    getContactById: build.query({
      query: (id) => `/api/contacts/${id}`,
      providesTags: ["Contacts"],
      transformResponse: customTransformResponse,
    }),
    getContactPreferences: build.query({
      query: (id) => `/api/contacts/${id}/frequencyrules`,
      providesTags: ["Contacts"],
      // transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
    }),
    getAllChannels: build.query({
      query: (id) => `/api/contacts/channels`,
      providesTags: ["Contacts"],
      // transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
    }),
    getContactAdvanced: build.query({
      query: (id) => `/api/contacts/${id}/advanced`,
      keepUnusedDataFor: 10,
      providesTags: ["Batch"],
      // transformResponse: customTransformResponse,
    }),
    addContact: build.mutation({
      query: (body) => ({
        url: `/api/contacts/new`,
        method: "POST",
        body,
      }),
    }),
    addContactCategory: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/contacts/${id}/categories`,
        method: "POST",
        body,
      }),
    }),
    addCompanyToContact: build.mutation({
      query: ({ companyId, contactId }) => ({
        url: `/api/companies/${companyId}/contact/${contactId}/add`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    downloadFile: build.mutation({
      query: (filename) => ({
        url: `/api/contacts/uploads/download/${filename}`,
        method: "POST",
        responseHandler: (response) => response.blob(), // Use response.blob() to handle binary data
      }),
    }),
    removeCompanyToContact: build.mutation({
      query: ({ companyId, contactId }) => ({
        url: `/api/companies/${companyId}/contact/${contactId}/remove`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    addSegmentsToContact: build.mutation({
      query: ({ segmentsId, contactId }) => ({
        url: `/api/segments/${segmentsId}/contact/${contactId}/add`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    removeSegmentsToContact: build.mutation({
      query: ({ segmentsId, contactId }) => ({
        url: `/api/segments/${segmentsId}/contact/${contactId}/remove`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    manageCampaignsToContacts: build.mutation({
      query: ({ campaignId, contactId, action }) => ({
        url: `/api/campaigns/${campaignId}/contact/${contactId}/${action}`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    setPrimary: build.mutation({
      query: ({ companyId, contactId }) => ({
        url: `/api/companies/${companyId}/contact/${contactId}/setPrimary`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    exportContact: build.mutation({
      query: (body) => ({
        url: `/api/contacts/export/custom`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "Contacts" }],
    }),
    addDoNotContact: build.mutation({
      query: ({ id }) => ({
        url: `/api/contacts/${id}/dnc/email/add`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    removeDoNotContact: build.mutation({
      query: ({ id }) => ({
        url: `/api/contacts/${id}/channels/remove`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    updateContacts: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/contacts/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Contacts", id }],
    }),
    updatePreferences: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/contacts/${id}/prefcenter`,
        method: "Put",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Contacts", id }],
    }),
    mergeContacts: build.mutation({
      query: ({ mainId, secondId }) => (
        console.log(mainId),
        console.log(secondId),
        {
          url: `api/contacts/merge/${mainId}/${secondId}`,
          method: "POST",
        }
      ),
    }),
    //SEND EMAIL
    sendEmail: build.mutation({
      query: ({ id, body }) => ({
        url: `api/contacts/${id}/email`,
        method: "POST",
        body,
      }),
    }),
    deleteContacts: build.mutation({
      query: (id) => ({
        url: `/api/contacts/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          contactsApi.util.updateQueryData("getContacts", undefined, (draft) => {
            draft.data = draft.data.filter((contact) => contact.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: ["Contacts"],
    }),
    //TABS VIEW CONTACT
    //Reservations
    getReservations: build.query({
      query: (id) => `/api/reservations/lead/${id}`,
      keepUnusedDataFor: 10,
      invalidatesTags: [{ type: "Contacts" }],
      // transformResponse: customTransformResponse,
    }),
    //Audit Log
    auditLogTab: build.query({
      query: ({ id, query, includeEvents }) => ({
        url: `/api/contacts/${id}/auditlog`,
        method: "POST",
        body: {
          ...query,
          ...{
            page: query.pageIndex + 1,
            limit: query.pageSize,
            includeEvents: includeEvents, // Include the 'includeEvents' in the request body
          },
        },
      }),
      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    //TICKETS
    seeTicketsTab: build.query({
      query: ({ id, query }) => ({
        url: `/api/showare/${id}/tickets`,
        method: "POST",
        body: {
          ...query,
          ...{
            page: query.pageIndex + 1,
            limit: query.pageSize,
          },
        },
      }),
      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    //FILES
    filesTab: build.query({
      query: ({ id, query }) => ({
        url: `/api/contacts/uploads/${id}`,
        method: "POST",
        body: {
          ...query,
          ...{
            page: query.pageIndex + 1,
            limit: query.pageSize,
          },
        },
      }),
      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    filesUploadTab: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/contacts/${id}/upload`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),

    deleteUploadFile: build.mutation({
      query: (id) => ({
        url: `/api/contacts/uploads/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Contacts", id }],
    }),

    //NOTES
    noteTab: build.query({
      query: ({ id, query, noteTypes }) => ({
        url: `/api/contacts/notes/${id}`,
        method: "POST",
        body: {
          ...query,
          ...{
            page: query.pageIndex + 1,
            limit: query.pageSize,
            noteTypes: noteTypes, // Include the 'noteTypes' in the request body
          },
        },
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    noteById: build.query({
      query: ({ id }) => ({
        url: `/api/contacts/notes/${id}`,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      invalidatesTags: [{ type: "Contacts" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    addNote: build.mutation({
      query: (body) => ({
        url: `/api/contacts/notes/new`,
        method: "POST",
        body,
      }),
    }),
    addDocumentNote: build.mutation({
      query: (body) => ({
        url: `/api/notes/document/new`,
        method: "POST",
        body,
      }),
      responseHandler: (response) => response.text(),
    }),

    deleteDocumentNote: build.mutation({
      query: (id) => ({
        url: `/api/notes/documents/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Contacts", id }],
    }),

    downloadNoteDocument: build.mutation({
      query: (filename) => ({
        url: `/api/notes/documents/download/${filename}`,
        method: "POST",
        responseHandler: (response) => response.blob(), // Use response.blob() to handle binary data
      }),
    }),
    addNoteTags: build.mutation({
      query: (body) => ({
        url: `/api/notes/tags/new`,
        method: "POST",
        body,
      }),
    }),
    addNoteActionTags: build.mutation({
      query: (body) => ({
        url: `/api/notes/action/tags/new`,
        method: "POST",
        body,
      }),
    }),
    getNoteActionTags: build.query({
      query: (type) => `/api/notes/action/tags/select`,
    }),
    getNoteTags: build.query({
      query: (type) => `/api/notes/tags/select`,
    }),

    deleteNote: build.mutation({
      query: (id) => ({
        url: `/api/contacts/notes/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Contacts", id }],
    }),
    updateNote: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/contacts/notes/${id}/edit`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Contacts", id }],
    }),
    //HISTORY/TIMELINE
    historyTab: build.query({
      query: ({ id, query, includeEvents }) => ({
        url: `/api/contacts/timeline/${id}`,
        method: "POST",
        body: {
          ...query,
          ...{
            page: query.pageIndex + 1,
            limit: query.pageSize,
            includeEvents: includeEvents, // Include the 'includeEvents' in the request body
          },
        },
      }),
      invalidatesTags: [{ type: "Contacts" }],
    }),

    //TRANSACTIONS
    getTranscationById: build.query({
      query: ({ id, q }) =>
        `/api/translations/lead/${id}?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      transformResponse: customTransformResponse,
    }),

    getTransactionsSources: build.query({
      query: (id) => `/api/translations/select/sources`,
    }),

    //BATCH OPERATIONS
    updateContactCampaign: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchCampaign`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    updateContactCategories: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchCategories`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    updateContactSegments: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchSegments`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    updateContactStage: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchStage`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    updateContactOwner: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchOwner`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    updateContactDNC: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchDNC`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    updateContactChannels: build.mutation({
      query: (body) => ({
        url: `/api/contacts/batchChannels`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Batch"],
    }),
    //Runner Global Command
    runnerCommand: build.mutation({
      query: (body) => ({
        url: `/api/runner/command/execute`,
        method: "POST",
        body,
      }),
    }),
    exportDownload: build.mutation({
      query: (objectId) => ({
        url: `/api/exports/download/${objectId}`,
        method: "POST",
        responseHandler: (response) => response.blob(), // Use response.blob() to handle binary data
      }),
    }),
    exportPrefContact: build.mutation({
      query: (id) => ({
        url: `/api/contacts/${id}/export`,
        method: "POST",
        responseHandler: (response) => response.blob(), // This tells RTK Query to treat the response as a Blob
      }),
      invalidatesTags: [{ type: "Contacts" }],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useDeleteContactsMutation,
  useUpdateContactsMutation,
  useAddDoNotContactMutation,
  useRemoveDoNotContactMutation,
  useMergeContactsMutation,
  useAddContactMutation,
  useGetContactByIdQuery,
  useGetContactsCategoriesQuery,
  useAddContactCategoryMutation,
  useGetContactAdvancedQuery,
  useAddCompanyToContactMutation,
  useRemoveCompanyToContactMutation,
  useSetPrimaryMutation,
  useManageCampaignsToContactsMutation,
  useAddSegmentsToContactMutation,
  useRemoveSegmentsToContactMutation,
  useGetContactPreferencesQuery,
  useUpdatePreferencesMutation,
  useAuditLogTabQuery,
  useNoteTabQuery,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useNoteByIdQuery,
  useHistoryTabQuery,
  useSeeTicketsTabQuery,
  useSendEmailMutation,
  useExportContactMutation,
  useUpdateContactCampaignMutation,
  useUpdateContactCategoriesMutation,
  useUpdateContactSegmentsMutation,
  useUpdateContactStageMutation,
  useUpdateContactOwnerMutation,
  useUpdateContactDNCMutation,
  useUpdateContactChannelsMutation,
  useGetAllChannelsQuery,
  useFilesTabQuery,
  useDeleteUploadFileMutation,
  useFilesUploadTabMutation,
  useDownloadFileMutation,
  useRunnerCommandMutation,
  useExportDownloadMutation,
  useGetReservationsQuery,
  useExportPrefContactMutation,
  useAddDocumentNoteMutation,
  useGetNoteActionTagsQuery,
  useGetNoteTagsQuery,
  useAddNoteTagsMutation,
  useAddNoteActionTagsMutation,
  useDownloadNoteDocumentMutation,
  useGetTranscationByIdQuery,
  useGetTransactionsSourcesQuery,
  useDeleteDocumentNoteMutation,
} = contactsApi;
