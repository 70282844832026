import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import {
  useAddFolderAssetsMutation,
  useDeleteAssetsMutation,
  useDeleteFolderAssetsMutation,
  useGetAssetsQuery,
  useGetFolderAssetsQuery,
  useRemoveAssetsToFoldersMutation,
  useUpdateAssetsMutation,
  useUpdateAssetsToFoldersMutation,
  useUpdateFolderAssetsMutation,
} from "../../../../redux/api/assetsApi";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { PreviewModal } from "./Modal/PreviewModal";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import { useTranslation } from "react-i18next";
import { AssetSearchHelper } from "../../../reusableComponents/HelperTexts/SearchHelper";

function Assets(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const [idModal, setIdModal] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const name = "Assets";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "assets",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "assets",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const body = [<CampaignForm control={control} />];
  const { data, isFetching, refetch: refetchTableData } = useGetAssetsQuery(query);
  const { data: folderAssets, refetch } = useGetFolderAssetsQuery();
  console.log(folderAssets);
  console.log(data);
  const [updateAsset] = useUpdateAssetsMutation();
  const [deleteAsset] = useDeleteAssetsMutation();
  const [addFolder] = useAddFolderAssetsMutation();
  const [updateFolder] = useUpdateFolderAssetsMutation();
  const [deleteFolder] = useDeleteFolderAssetsMutation();
  const [updateAssetsToFolder] = useUpdateAssetsToFoldersMutation();
  const [removeAssetsFromFolder] = useRemoveAssetsToFoldersMutation();

  const handleTogglePublished = async (data) => {
    event.stopPropagation();
    setLocalLoading(data.id);
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
    };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateAsset(updatedData);
    await refetch();
    setLocalLoading(-1);
  };

  const openModal = async (id) => {
    setIdModal(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setIdModal(null);
    setModalIsOpen(false);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "title",
      accessorFn: (row) => row.title,
      header: t("caymland.core.title"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="assets"
          view={true}
          permissionKey= "asset:assets"
        />
      ),
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.page.tracking.form.category"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "downloadCount",
      accessorFn: (row) => row.downloadCount,
      header: t("caymland.asset.abtest.label.downloads"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.downloadCount,
    },
  ]);

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/assets/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "assets",
    deleteItem: deleteAsset,
    refetch: refetchTableData,
    removeItemFromFolder: removeAssetsFromFolder,
    clone: true,
    preview: true,
    showRemove: activeRowId !== null,
    folderId: activeRowId,
    openModal: openModal,
    permissionKey: "asset:assets",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "assets",
    refetch: refetchTableData,
    permissionKey: "asset:assets",
  });

  return (
    <Fragment>
      {idModal && <PreviewModal modalIsOpen={modalIsOpen} closeModal={closeModal} id={idModal} />}
      <Header buttons={buttons} title={t("caymland.asset.assets")} />
      <DragAndDropTable
        data={data}
        folderData={folderAssets}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateAssetsToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        searchHelperBody={<AssetSearchHelper />}
      />
    </Fragment>
  );
}

export default Assets;
