import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/sidebar_logo.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthLoginMutation } from "../../redux/api/authApi";
import { Text, Select } from "../reusableComponents/Inputs";
import LastUpdate from "./last_update.json";
import Moment from "moment";
import "moment/locale/de";
import { useTranslation } from "react-i18next";
import { SlideAlert } from "../reusableComponents/Alerts";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../common/loader";
import axios from "axios";

Moment.locale("de");

function Login() {
  const { t } = useTranslation();

  const resolver = useMemo(
    () =>
      yup.object().shape({
        _username: yup.string().required(t("Username is required")),
        _password: yup.string().required(t("Password is required")),
      }),
    [t]
  );

  const [authLogin, { isLoading }] = useAuthLoginMutation();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [loadingBackground, setLoadingBackground] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      _username: null,
      _password: null,
    },
    resolver: yupResolver(resolver),
  });

  const fetchBackgroundImage = async () => {
    try {
      setLoadingBackground(true);

      const response = await axios.get(
        "https://api.unsplash.com/photos/random?query=nature&client_id=iOkMPl0_Ai2kMnxIbWO7t14QXE7sx_HSUa6YFG9VD00",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "no-cors",
            "Referrer-Policy": "no-referrer",
          },
        }
      );
      const data = await response;
      console.log(data);

      // Extract the first landscape image URL
      const firstItem = data?.data?.batchrsp?.items?.[0];
      const landscapeImageUrl = firstItem ? JSON.parse(firstItem.item).ad.landscapeImage.asset : null;

      if (data) {
        // Save image and fetch time to localStorage
        localStorage.setItem("backgroundUrl", data.data.urls.raw);
        localStorage.setItem("backgroundFetchTime", Date.now());
        setBackgroundUrl(data.data.urls.raw);
      } else {
        console.error("No landscape image found in the API response.");
      }
    } catch (error) {
      console.error("Error fetching background image:", error);
    } finally {
      setLoadingBackground(false);
    }
  };

  useEffect(() => {
    // Check if a background image already exists in localStorage
    const storedBackgroundUrl = localStorage.getItem("backgroundUrl");
    const backgroundFetchTime = localStorage.getItem("backgroundFetchTime");
    const currentTime = Date.now();

    // If the image exists and was fetched within the last hour, use it
    if (
      storedBackgroundUrl &&
      backgroundFetchTime &&
      currentTime - backgroundFetchTime < 60 * 60 * 1000 // 1 hour in milliseconds
    ) {
      setBackgroundUrl(storedBackgroundUrl);
      setLoadingBackground(false);
    } else {
      // Otherwise, fetch a new image
      fetchBackgroundImage();
    }

    // Set an interval to refresh the background every hour
    const interval = setInterval(() => {
      fetchBackgroundImage();
    }, 60 * 60 * 1000); // 1 hour

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const login = async (credentials) => {
    const response = await authLogin(credentials);
    if (response.error) {
      setShow(true);
      setMessage(t("Invalid login. Please verify credentials."));
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 5000);
    } else {
      const redirectPath =
        location.state?.from?.pathname && location.state.from.pathname !== "/login"
          ? location.state.from.pathname
          : "/";
      navigate(redirectPath);
    }
  };

  const [instance, setInstance] = useState({ label: "dev.m-4.ch", value: "dev.m-4.ch" });
  const onChangeInstance = (data) => {
    setInstance(data);
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Fragment>
      <SlideAlert background={2} show={show} message={message} setShowAlert={setShow} />
      <div className="page-wrapper">
        <div
          className="auth-bg"
          style={{
            backgroundImage: loadingBackground ? (
              <Loader />
            ) : (
              `url(${backgroundUrl || "http://localhost:3000/static/media/auth-bg-1.8184142c45a47fd52a9c.jpg"})`
            ),
          }}
        >
          <div className="authentication-box">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
              <img src={logo} alt="company logo" />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <form onSubmit={handleSubmit(login)}>
                  <div className="text-center">
                    <h4>{t("Login")}</h4>
                  </div>
                  <div className="">
                    <Controller
                      control={control}
                      name="_username"
                      render={({ field: { name, onChange, value } }) => (
                        <Text name={name} value={value} onChange={onChange} label={t("Username")} />
                      )}
                    />
                  </div>
                  {errors._username && <span style={{ color: "#FF0000" }}>{errors._username.message}</span>}
                  <div className="">
                    <Controller
                      control={control}
                      name="_password"
                      render={({ field: { name, onChange, value } }) => (
                        <div className="password-input-wrapper" style={{ position: "relative" }}>
                          <Text
                            name={name}
                            value={value}
                            type={showPassword ? "text" : "password"}
                            onChange={onChange}
                            label={t("Password")}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "68%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              fontSize: "1.5em",
                            }}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                  {errors._password && <span style={{ color: "#FF0000" }}>{errors._password.message}</span>}
                  <div className="forgotPassword tw-mt-[20px]">
                    <Link to="/reset">{t("Forgot Password?")}</Link>
                  </div>
                  <div className="form-group form-row mt-3 mb-0 d-grid">
                    <input type="submit" className="btn active-btn" value={isLoading ? t("Loading...") : t("Login")} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
