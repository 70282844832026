import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Select } from "../Inputs";
import { t } from "i18next";

export const MRT_ToggleSelect = ({ data, value, onChange, title, isMulti = false }) => {
  const [showPicker, setShowPicker] = useState(isMulti ? true : false);

  console.log(value);
  console.log(data);

  const handleToggleSearch = () => {
    setShowPicker(!showPicker);
  };

  const handleChange = (selectedOptions) => {
    if (isMulti) {
      // For multi-select, update with an array of values or an empty array
      onChange(selectedOptions ? selectedOptions.map((option) => option.value) : []);
    } else {
      // For single select, update with a single value or null
      onChange(selectedOptions ? selectedOptions.value : null);
    }
  };

  // Correctly set the value for Select component in case of multi-select
  const selectedValues = isMulti
    ? data?.filter((option) => value.includes(option.value))
    : data?.find((option) => option.value === value);

  return (
    <>
      <Collapse in={showPicker} orientation="horizontal" unmountOnExit mountOnEnter>
        <div style={{ width: "300px" }}>
          <Select
            document={true}
            name="types"
            options={data}
            value={selectedValues}
            placeholder={
              isMulti
                ? t("caymland.lead.lead.filter.bundles.include.placeholder")
                : t("caymland.query.builder.select.placeholder")
            }
            onChange={handleChange}
            margin={true}
            isMulti={isMulti}
          />
        </div>
      </Collapse>
      <Tooltip arrow title={`${t("caymland.core.filter.header")} ${title}...` ?? t("caymland.core.filter.header")}>
        <IconButton onClick={handleToggleSearch} title={title}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
