import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import flattenDeep from "lodash/flattenDeep";
import NotFoundPage from "../NotFound/NotFoundPage";
import ForbiddenPage from "../Forbidden/ForbiddenPage"; // Import the ForbiddenPage component

const generateFlattenRoutes = (routes, hasPermission, plugins, inheritedPermissionKey = null) => {
  if (!routes) return [];

  return flattenDeep(
    routes.map(({ children: subRoutes, permissionKey, permissionValue, pluginName, element, ...rest }) => {
      const currentPermissionKey = permissionKey ?? inheritedPermissionKey;

      const routeElement = pluginName && !plugins.includes(pluginName) ? <NotFoundPage /> : element;

      if (currentPermissionKey && !hasPermission(currentPermissionKey, permissionValue)) {
        return [
          {
            ...rest,
            element: <ForbiddenPage />,
            permissionKey: currentPermissionKey,
            permissionValue,
            pluginName,
            isForbidden: true,
          },
        ];
      }

      return [
        { ...rest, element: routeElement, permissionKey: currentPermissionKey, permissionValue, pluginName },
        subRoutes ? generateFlattenRoutes(subRoutes, hasPermission, plugins, currentPermissionKey) : [],
      ];
    })
  );
};

export const RoutesComponent = ({ mainRoutes }) => {
  const { rawPermissions: permissions = {}, isAdmin } = useSelector((state) => state?.auth?.user?.role || {});
  const plugins = useSelector((state) => state?.auth?.user?.plugins || []);

  const hasPermission = (permissionKey, permissionValue) => {
    if (isAdmin) return true;
    if (permissionKey in permissions) {
      if (!permissionValue || permissions[permissionKey].includes("full")) {
        return true;
      }
      return permissions[permissionKey].includes(permissionValue);
    }
    return false;
  };

  return (
    <ReactRoutes>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      {mainRoutes.map(({ layout: Layout, routes: subRoutes, permissionKey }, index) => {
        const subRoutes2 = generateFlattenRoutes(subRoutes, hasPermission, plugins, permissionKey);
        return (
          <Route key={index} element={<Layout />}>
            {subRoutes2.map(({ element, path, name, isForbidden }) => (
              <Route
                key={name || path}
                path={path}
                element={isForbidden ? <ForbiddenPage /> : element || <NotFoundPage />}
              />
            ))}
          </Route>
        );
      })}
      <Route path="*" element={<NotFoundPage />} />
    </ReactRoutes>
  );
};
