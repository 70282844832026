import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import {
  useAddFolderDwcMutation,
  useDeleteDwcMutation,
  useDeleteFolderDwcMutation,
  useGetFolderDwcQuery,
  useGetdwcQuery,
  useRemoveDwcToFoldersMutation,
  useUpdateDwcMutation,
  useUpdateDwcToFoldersMutation,
  useUpdateFolderDwcMutation,
} from "../../../../redux/api/dwcApi";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MRT_CustomRowsActionFolder } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { t } from "i18next";
import { WebHookSearchHelper } from "../../../reusableComponents/HelperTexts/SearchHelper";

function DynamicContent() {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const [activeRowId, setActiveRowId] = useState(null);
  const [editingFolder, setEditingFolder] = useState();
  const name = "DynamicContent";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "dynamiccontents",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "dynamiccontents",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const { data: dwcData, isFetching: dwcIsFetching, refetch: refetchTableData } = useGetdwcQuery(query);
  const { data: folderDwcData, refetch } = useGetFolderDwcQuery();
  const [updateDwc] = useUpdateDwcMutation();
  const [deleteDwc] = useDeleteDwcMutation();
  const [addFolder] = useAddFolderDwcMutation();
  const [updateFolder] = useUpdateFolderDwcMutation();
  const [deleteFolder] = useDeleteFolderDwcMutation();
  const [updateDwcToFolder] = useUpdateDwcToFoldersMutation();
  const [removeDwcFromFolder] = useRemoveDwcToFoldersMutation();

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { ...data, isPublished: !data.isPublished };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateDwc(updatedData);
    await refetchTableData();
    setLocalLoading(-1);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.core.name"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => {
        const rowDataWithoutContent = { ...cell.row.original };
        delete rowDataWithoutContent.content; // Remove the 'content' field
        return (
          <LinkComponent
            data={rowDataWithoutContent}
            loading={localLoading}
            handleTogglePublished={handleTogglePublished}
            link="dwc"
            view={true}
            permissionKey="dynamiccontent:dynamiccontents"
          />
        );
      },
    },
    {
      accessorKey: "slotName",
      accessorFn: (row) => row.slotName,
      header: t("caymland.dynamicContent.label.slot_name"),
      size: "unset",

      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.slotName,
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.page.tracking.form.category"),
      size: "unset",

      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const body = [<CampaignForm control={control} />];

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/dwc/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "dwc",
    deleteItem: deleteDwc,
    removeItemFromFolder: removeDwcFromFolder,
    refetch: refetchTableData,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    permissionKey: "dynamiccontent:dynamiccontents",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "dynamiccontents",
    refetch: refetchTableData,
    permissionKey: "dynamiccontent:dynamiccontents",
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.core.dynamicContent")} />
      <DragAndDropTable
        data={dwcData}
        folderData={folderDwcData}
        columns={columns}
        isFetching={dwcIsFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateDwcToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        searchHelperBody={<WebHookSearchHelper />}
      />
    </Fragment>
  );
}

export default DynamicContent;
